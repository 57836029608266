/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useRef } from 'react'
import { useState } from 'react'
import { Api } from '../../api'
import { useDidMount } from 'rooks'
import { LoadingService } from '../../services/loading.service'
import { BasketService } from '../../services/basket.service'
import { KTSVG } from '../../_metronic/helpers'
import { HomepageDataContext } from '../../context/HomepageDataContext'

type Props = {
  className: string
}

type UrunProps = {
  code: string
  name: string
  brand: string
  available: number | string
  price: number | string
  kur: number
  depoMiktar?: Record<string, number | string>
}

const Urun: React.FC<UrunProps> = ({ code, name, brand, available, price, kur, depoMiktar }) => {
  const sepetMiktarRef = useRef<HTMLInputElement>()

  // if (!available || available <= 0) return null
  if (!price || price <= 0) return null

  let udata = Api.getLocalUserData()
  if (!udata) return null

  const isAdmin = udata.type === 'admin'
  const brandDiscount = isAdmin ? 0 : Api.getDiscount(brand)
  const brandDiscountedPrice = +(parseFloat(String(price)) * (1 - brandDiscount)).toFixed(3)
  const specialDiscountedPrice = Api.getDiscountedForCode(code)

  const kurBrandDiscountedPrice = +(parseFloat(String(price)) * (1 - brandDiscount) * kur).toFixed(2)
  const kurSpecialDiscountedPrice = +((specialDiscountedPrice ? specialDiscountedPrice : 0) * kur).toFixed(2)

  const picUrl = Api.createProductUrl(code)

  async function ekle() {
    const sptMikt = parseInt(sepetMiktarRef.current?.value ?? '0')

    if (!sptMikt || sptMikt <= 0) {
      alert('Geçersiz sepet miktarı girdiniz.')
      return
    }

    LoadingService.show()

    let prevp = BasketService.getLocalBasketDataList()
    if (prevp) prevp += ','
    let prevc = BasketService.getLocalBasketDataCount()
    if (prevc) prevc += ','

    await Api.updateBasket(prevp + code, prevc + sptMikt.toString())

    await BasketService.downloadAndUpdateBasketData()

    LoadingService.hide()
  }

  return (
    <tr className='ka-ara-row'>
      <td className='ka-ara-row-pic'>
        <a href={picUrl} target="_blank" rel="noreferrer"><img src={picUrl} alt="" /></a>
      </td>
      <td>
        <span className='text-dark fw-bold fs-6'>{code.toUpperCase()}</span>
      </td>
      <td>
        <span className='text-dark fw-bold fs-6 opacity-75'>{name.toLocaleUpperCase()}</span>
      </td>
      <td>
        <span className='text-dark fw-bold fs-6 opacity-75'>{brand.toUpperCase()}</span>
      </td>
      {isAdmin && (
        <td>
          <span className='text-dark fw-bold fs-6 opacity-75'>
            {available > 20 ? (isAdmin ? available : '20+') : available}
          </span>
        </td>
      )}
      {depoMiktar ? (
        <>
          {isAdmin ? (
            <td>
              <span className='text-dark fw-bold fs-6 opacity-75'>{depoMiktar.merkez ?? '?'}</span>
            </td>
          ) : (
            <td>
              {depoMiktar.merkez !== undefined ? (
                <span className='text-dark fw-bold fs-6 opacity-75'>
                  {+depoMiktar.merkez > 20 ? '20+' : depoMiktar.merkez}
                </span>
              ) : '?'}
            </td>
          )}

          {isAdmin ? (
            <td>
              <span className='text-dark fw-bold fs-6 opacity-75'>{depoMiktar.topkapi ?? '?'}</span>
            </td>
          ) : null}

          {isAdmin && (
            <td>
              <span className='text-dark fw-bold fs-6 opacity-75'>{depoMiktar.surgun ?? '?'}</span>
            </td>
          )}

          {isAdmin ? (
            <td>
              <span className='text-dark fw-bold fs-6 opacity-75'>{depoMiktar.ankara ?? '?'}</span>
            </td>
          ) : (
            <td>
              {depoMiktar.ankara !== undefined ? (
                <span className='text-dark fw-bold fs-6 opacity-75'>
                  {+depoMiktar.ankara > 20 ? '20+' : depoMiktar.ankara}
                </span>
              ) : '?'}
            </td>
          )}
        </>
      ) : null}
      <td className='text-dark fw-bold fs-6'>
        {specialDiscountedPrice ? (
          <>
            <del>${brandDiscountedPrice.toString().split('.').join(',')}</del>
            <br />${specialDiscountedPrice.toString().split('.').join(',')}
          </>
        ) : (
          <>${brandDiscountedPrice.toString().split('.').join(',')}</>
        )}
      </td>
      <td className='text-dark fw-bold fs-6'>
        {kurSpecialDiscountedPrice ? (
          <>
            <del>{kurBrandDiscountedPrice.toString().split('.').join(',')}</del>₺
            <br />
            {kurSpecialDiscountedPrice.toString().split('.').join(',')}₺
          </>
        ) : (
          <>{kurBrandDiscountedPrice.toString().split('.').join(',')}₺</>
        )}
      </td>
      {!available || available <= 0 ? (
        <>
          <td />
          <td />
        </>
      ) : (
        <>
          <td className='text-dark fw-bold fs-6 text-end w-75px'>
            <input
              ref={sepetMiktarRef as any}
              type='number'
              className='form-control form-control-solid text-center no-arrows px-2 py-2'
              defaultValue={1}
              min={1}
            />
          </td>
          <td className='text-dark fw-bold fs-6 text-end py-3'>
            <span className='ka-ekle' role='button' onClick={ekle}>
              Ekle
            </span>
          </td>
        </>
      )}
    </tr>
  )
}

const Urunler: React.FC<Props> = ({ className }) => {
  const [products, setProducts] = useState<any[]>([])
  const [isSearching, setIsSearching] = useState(false)
  const [kur, setKur] = useState(0)
  const tableRef = useRef<HTMLTableElement | null>()

  const data = useContext(HomepageDataContext)

  useDidMount(() => {
    if (!searchtext) return
    setIsSearching(true)
    Api.getKur().then((kurdata) => {
      if (kurdata && kurdata.success) {
        setKur(kurdata.data.USDTRY)
      } else {
        console.log('Kur çekilemedi')
      }
    })
    Api.searchProduct(searchtext).then((result) => {
      if (!result.success) {
        alert('Sistemde bir hata oluştu')
      }

      setProducts(result.data)
      setIsSearching(false)
    })
  })

  const isAdmin = Api.isAdmin()

  var url = window.location.search
  url = url.replace('?', '')
  const searchParams = new URLSearchParams(url)
  let searchtext = String(searchParams.get('search') ?? '').trim()
  searchtext = Api.normalizeSearchStr(searchtext)

  return (
    <>
      <div className='d-flex flex-wrap d-grid gap-5 gap-xxl-6 justify-content-start mb-4'>
        <div className={'card card-flush flex-row-fluid p-1 mw-100 ka-product2'}>
          <div className='card-body fs-5 fw-semibold text-dark' dangerouslySetInnerHTML={{ __html: data.not }} />
        </div>
      </div>

      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {searchtext ? `Ürün arama: "${searchtext}" ve muadilleri` : 'Tüm Ürünler'}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              20 ürüne kadar arama sonucunuz ile ilgili ürünler gösteriliyor*
            </span>
            <br />
            <span className='fs-4'>
              <b>Dolar kuru:</b>&nbsp;&nbsp;$1 = {kur.toFixed(3)}₺
            </span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Sadece stokta bulunan ürünler gösteriliyor*</span> */}
            <br />
            <br />
          </h3>
        </div>
        <div className='card-body py-1'>
          <div className='row justify-content-end pe-4 mb-5'>
            <div
              className='ka-tablo-sol btn-primary btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => {
                if (!tableRef.current) return
                tableRef.current.scrollLeft -= 60
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2x' />
            </div>
            <div
              className='ka-tablo-sag btn btn-sm btn-primary btn-icon btn-active-color-primary ms-2'
              onClick={() => {
                if (!tableRef.current) return
                tableRef.current.scrollLeft += 60
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-2x' />
            </div>
          </div>
          <div ref={tableRef as any} className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th></th>
                  <th className='min-w-150px'>Ürün Kodu</th>
                  <th className='min-w-120px'>Adı</th>
                  <th className='min-w-120px'>Marka</th>
                  {isAdmin && <th className='min-w-50px'>Stok Durumu</th>}
                  <th className='min-w-50px'>(Istanbul)</th>
                  {isAdmin && <th className='min-w-50px'>(Topkapı)</th>}
                  {isAdmin && <th className='min-w-50px'>(Surgun)</th>}
                  <th className='min-w-50px'>(Ankara)</th>
                  <th className='min-w-120px'>Fiyat ($)</th>
                  <th className='min-w-120px'>Fiyat (₺)</th>
                  <th className='w-75px text-end'>Miktar</th>
                  <th className='w-75px text-end'>Sepete Ekle</th>
                </tr>
              </thead>
              <tbody>
                {(!searchtext || products.length === 0) && !isSearching ? (
                  <tr>
                    <td className='text-muted pt-6 fs-6'>Girdiğiniz kriterlere uygun bir ürün bulunamadı.</td>
                  </tr>
                ) : null}
                {isSearching ? (
                  <tr>
                    <td className='text-muted pt-6 fs-6'>Yükleniyor...</td>
                  </tr>
                ) : null}

                {products.map((v, i) => (
                  <Urun
                    kur={kur}
                    code={v.code}
                    name={v.name}
                    depoMiktar={v.depoMiktar}
                    brand={v.category}
                    available={v.availability}
                    price={String(+v.price.toFixed(3))}
                    key={i}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export { Urunler }
