import clsx from 'clsx'
import { Api } from '../../../../api'
import { useAuth } from '../../../../app/modules/auth'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu, Search } from '../../../partials'
import { Cart } from '../../../partials/layout/search/Cart'
import { useLayout } from '../../core'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const { config } = useLayout()
  const { currentUser } = useAuth()

  let udata: any = {}

  try {
    udata = Api.getLocalUserData()
  } catch {
    return null
  }

  const isAdmin = udata.type === 'admin'

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />

        <Cart />
      </div>

      {isAdmin ? null : (
        <div className={clsx('app-navbar-item', itemClass, 'ms-2 ms-lg-2')}>
          <div className='ka-bakiye-wrapper'>
            <span className='ka-balance text-dark fw-bold fs-6 px-3'>
              <span className='text-muted fw-bold d-inline'>Cari Bakiye: </span>{' '}
              <span className='px-0'>
                {parseFloat(String(currentUser?.data?.balance ?? '0'))
                  .toFixed(0)
                  .replace('.', ',')}{' '}
                TL
              </span>
            </span>
            <span className='ka-balance ka-vadesigecen text-dark fw-bold fs-6 px-3'>
              <span className='fw-bold d-inline' style={{ color: '#dc3547' }}>
                Vadesi Geçen Bakiye:{' '}
              </span>{' '}
              <span className='px-0'>
                {parseFloat(String(currentUser?.data?.vadesigecen ?? '0'))
                  .toFixed(0)
                  .replace('.', ',')}{' '}
                TL
              </span>
            </span>
          </div>
        </div>
      )}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img style={{ borderRadius: '30px' }} src={toAbsoluteUrl('/default-avatar.jpg')} alt='' />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div className='btn btn-icon btn-active-color-primary w-35px h-35px' id='kt_app_header_menu_toggle'>
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export { Navbar }
