/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react'
import { KTSVG } from '../../_metronic/helpers'

type Props = {
  className: string
  linkler: string[]
  yazilar: string[]
}

const Links = ({ className, linkler, yazilar }: Props) => {
  const rows: Array<{ description: string; link: string }> = [
    { description: yazilar[0], link: linkler[0] },
    { description: yazilar[1], link: linkler[1] },
    { description: yazilar[2], link: linkler[2] }
  ]

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <h3 className='card-title text-gray-800 fw-bold'>Bağlantılar</h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body pt-5'>
        {rows.map((row, index) => (
          <Fragment key={`lw26-rows-${index}`}>
            <div className='d-flex flex-stack'>
              <a href={row.link} target='_blank' className='text-primary fw-semibold fs-6 me-2'>
                {row.description}
              </a>
              <button type='button' className='btn btn-icon btn-sm h-auto btn-color-gray-400 justify-content-end'>
                <KTSVG path='media/icons/duotune/arrows/arr095.svg' className='svg-icon-2' />
              </button>
            </div>
            {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}
export { Links }
