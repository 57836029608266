import React, { useRef, useState } from "react"
import { useDidMount } from "rooks"
import { Api } from "../../api"
import { KTSVG } from "../../_metronic/helpers"
import { LoadingService } from "../../services/loading.service"
import { BasketService } from "../../services/basket.service"

type UrunProps = {
  code: string
  name: string
  brand: string
  available: number | string
  price: number | string
  kur: number
  depoMiktar?: Record<string, number | string>
  description: string | null
}

const Urun: React.FC<UrunProps> = ({ code, name, brand, available, price, kur, depoMiktar, description }) => {
  const sepetMiktarRef = useRef<HTMLInputElement>()

  // @ts-ignore
  if (!price || price <= 0) return null

  let udata = Api.getLocalUserData()
  if (!udata) return null

  const brandDiscountedPrice = +(parseFloat(String(price))).toFixed(3)

  const kurBrandDiscountedPrice = +(parseFloat(String(price)) * kur).toFixed(2)

  const picUrl = Api.createProductUrl(code)

  const ekle = async () => {
    const sptMikt = parseInt(sepetMiktarRef.current?.value ?? '0')

    if (!sptMikt || sptMikt <= 0) {
      alert('Geçersiz sepet miktarı girdiniz.')
      return
    }

    LoadingService.show()

    let prevp = BasketService.getLocalBasketDataList()
    if (prevp) prevp += ','
    let prevc = BasketService.getLocalBasketDataCount()
    if (prevc) prevc += ','

    await Api.updateBasket(prevp + code, prevc + sptMikt.toString())

    await BasketService.downloadAndUpdateBasketData()

    LoadingService.hide()
  }

  return (
    <tr className='ka-ara-row'>
      <td className='ka-ara-row-pic'>
        <a href={picUrl} target="_blank" rel="noreferrer"><img src={picUrl} alt="" /></a>
      </td>
      <td>
        <span className='text-dark fw-bold fs-6'>{code.toUpperCase()}</span>
      </td>
      <td>
        <span className='text-dark fw-bold fs-6 opacity-75'>{name.toLocaleUpperCase()}</span>
      </td>
      <td>
        <span className='text-dark fw-bold fs-6 opacity-75'>{brand.toUpperCase()}</span>
      </td>

      {depoMiktar ? (
        <>
          <td>
            {depoMiktar.merkez !== undefined ? (
              <span className='text-dark fw-bold fs-6 opacity-75'>
                {+depoMiktar.merkez > 20 ? '20+' : depoMiktar.merkez}
              </span>
            ) : '?'}
          </td>
          <td>
            {depoMiktar.ankara !== undefined ? (
              <span className='text-dark fw-bold fs-6 opacity-75'>
                {+depoMiktar.ankara > 20 ? '20+' : depoMiktar.ankara}
              </span>
            ) : '?'}
          </td>
        </>
      ) : null}
      <td className='text-dark fw-bold fs-6'>
        ${brandDiscountedPrice.toString().split('.').join(',')}
      </td>
      <td className='text-dark fw-bold fs-6'>
        {kurBrandDiscountedPrice.toString().split('.').join(',')}₺
      </td>
      <td className='text-dark fw-bold fs-6'>
        {description}
      </td>
      {/* @ts-ignore */}
      {!available || available <= 0 ? (
        <>
          <td />
          <td />
        </>
      ) : (
        <>
          <td className='text-dark fw-bold fs-6 text-end w-75px'>
            <input
              ref={sepetMiktarRef as any}
              type='number'
              className='form-control form-control-solid text-center no-arrows px-2 py-2'
              defaultValue={1}
              min={1}
            />
          </td>
          <td className='text-dark fw-bold fs-6 text-end py-3'>
            <span className='ka-ekle' role='button' onClick={ekle}>
              Ekle
            </span>
          </td>
        </>
      )}
    </tr>
  )
}

const KampanyaUrunleriPage: React.FC = () => {
  const tableRef = useRef<HTMLTableElement | null>()

  const [kur, setKur] = useState(0)

  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState<any[]>([])

  useDidMount(() => {
    setLoading(true)

    Api.getKur().then((kurdata) => {
      if (kurdata && kurdata.success) {
        setKur(kurdata.data.USDTRY)
      } else {
        console.log('Kur çekilemedi')
      }
    })

    Api.getKampanyaUrunler().then((data) => {
      if (data.success) {
        setProducts(data.products)
      }
    }).finally(() => {
      setLoading(false)
    })
  })

  return (
    <div className="card card-xxl-stretch mb-5 mb-xl-8">
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Kampanya Ürünleri
          </span>
          <br />
          <span className='fs-4'>
            <b>Dolar kuru:</b>&nbsp;&nbsp;$1 = {kur.toFixed(3)}₺
          </span>
          <br />
          <br />
        </h3>
      </div>
      <div className='card-body py-1'>
        <div className='row justify-content-end pe-4 mb-5'>
          <div
            className='ka-tablo-sol btn-primary btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => {
              if (!tableRef.current) return
              tableRef.current.scrollLeft -= 60
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2x' />
          </div>
          <div
            className='ka-tablo-sag btn btn-sm btn-primary btn-icon btn-active-color-primary ms-2'
            onClick={() => {
              if (!tableRef.current) return
              tableRef.current.scrollLeft += 60
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-2x' />
          </div>
        </div>

        <div ref={tableRef as any} className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th></th>
                <th className='min-w-150px'>Ürün Kodu</th>
                <th className='min-w-120px'>Adı</th>
                <th className='min-w-120px'>Marka</th>
                <th className='min-w-50px'>(Istanbul)</th>
                <th className='min-w-50px'>(Ankara)</th>
                <th className='min-w-120px'>Fiyat ($)</th>
                <th className='min-w-120px'>Fiyat (₺)</th>
                <th className='min-w-120px'>Özel Açıklama</th>
                <th className='w-75px text-end'>Miktar</th>
                <th className='w-75px text-end'>Sepete Ekle</th>
              </tr>
            </thead>
            <tbody>
              {(products.length === 0) && !loading ? (
                <tr>
                  <td className='text-muted pt-6 fs-6'>Kampanyalı ürün yok.</td>
                </tr>
              ) : null}
              {loading ? (
                <tr>
                  <td className='text-muted pt-6 fs-6'>Yükleniyor...</td>
                </tr>
              ) : null}

              {products.map((v, i) => (
                <Urun
                  kur={kur}
                  code={v.code}
                  name={v.name}
                  depoMiktar={v.depoMiktar}
                  brand={v.category}
                  available={v.availability}
                  price={String(+v.price.toFixed(3))}
                  description={v.description}
                  key={i}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export { KampanyaUrunleriPage as default }
