/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react'
import { useDidMount } from 'rooks'
import { Api } from '../../api'
import { LoadingService } from '../../services/loading.service'
import moment from 'moment'
import 'moment/locale/tr'
import { OrderStatusSelect } from './recentOrdersTaken'
import { KTSVG } from '../../_metronic/helpers'
import { OrderStatus } from '../../types'

export type RecentOrdersGivenProps = {
  className: string
  _orders?: CloudReturnedOrderType[]
  hundredOrders?: boolean
}

export type GivenOrderProps = {
  code: string
  date: moment.Moment
  odeme: string
  teslimat: string
  total: number | string
  status: OrderStatus
}

const GivenOrder: React.FC<GivenOrderProps> = ({ code, date, odeme, teslimat, total, status }) => {
  let udata: any = {}

  try {
    udata = Api.getLocalUserData()
  } catch {
    return null
  }

  const isAdmin = udata.type === 'admin'

  return (
    <tr>
      <td className='text-nowrap'>
        <a href={`/siparis/${code}`} className='text-dark fw-bold text-hover-primary fs-6'>
          {code}
        </a>
      </td>
      <td className='text-nowrap'>
        <span className='text-dark fw-bold fs-6'>{date.format('DD MMMM YYYY')}</span>
      </td>
      <td className='text-nowrap'>
        <span className='text-dark fw-bold fs-6'>{date.format('HH:mm')}</span>
      </td>
      <td className='text-nowrap'>
        <span className='text-dark fw-bold fs-6'>
          {odeme === 'cari' ? 'Cari Bakiye' : null}
          {odeme === 'online' ? 'Online Ödeme' : null}
        </span>
      </td>
      <td className='text-nowrap'>
        <span className='text-dark fw-bold fs-6'>
          {teslimat === 'kendim' ? 'Kendim Alacağım' : null}
          {teslimat === 'arac' ? 'Araç ile Teslimat' : null}
          {teslimat === 'kargo' ? 'Kargo ile Teslimat' : null}
        </span>
      </td>
      <td className='text-dark fw-bold fs-6 text-end text-nowrap'>
        ${(+(parseFloat(String(total)) * 1.20)).toFixed(3).split('.').join(',')}
      </td>
      <td className='text-end pl-8'>
        {isAdmin ? (
          <OrderStatusSelect code={code} status={status} teslimat={teslimat} />
        ) : (
          <>
            {status === 'Onay Bekliyor' ? <span className='badge badge-light-warning'>{status}</span> : null}
            {status === 'Onaylandı ve Hazırlanıyor' ? (
              <span className='badge' style={{ backgroundColor: '#FFE2CA', color: '#fd7e14' }}>{status}</span>
            ) : null}
            {status === 'Sipariş Teslim Alınabilir' ? (
              <span className='badge badge-light-info'>{status}</span>
            ) : null}
            {status === 'Teslim Alındı' ? (
              <span className='badge badge-light-success'>{status}</span>
            ) : null}
            {status === 'Dağıtım Aracımıza Yüklenecek' ? (
              <span className='badge badge-light-info'>{status}</span>
            ) : null}
            {status === 'Adresinize Bırakıldı' ? (
              <span className='badge badge-light-success'>{status}</span>
            ) : null}
            {status === 'Onaylandı ve Kargoya Verilecek' ? (
              <span className='badge badge-light-info'>{status}</span>
            ) : null}
            {status === 'İptal Edildi' ? <span className='badge badge-light-danger'>{status}</span> : null}
            {status === 'Kargoya Verildi' ? <span className='badge badge-light-success'>{status}</span> : null}
            {status === 'Parçalı Onay' ? <span className='badge badge-light-warning'>{status}</span> : null}
          </>
        )}
      </td>
    </tr>
  )
}

export interface CloudReturnedOrderType {
  code: string
  createdAt: string
  data: string
  delivery: string // arac, kargo
  note: string
  payment: string // cari
  status: string // ...
  total: number
  userId: number
  user?: any
}

const RecentOrdersGiven: React.FC<RecentOrdersGivenProps> = ({ _orders, className, hundredOrders = true }) => {
  const [orders, setOrders] = useState<CloudReturnedOrderType[]>(_orders ? _orders : [])
  const tableRef = useRef<HTMLTableElement | null>()

  useDidMount(async () => {
    if (_orders) return

    LoadingService.show()
    moment.locale('tr')

    const res = await Api.listOrders()

    if (!res.success) {
      LoadingService.hide()
      return alert('Bir hata oluştu: ' + res.error?.message ?? '')
    } else {
      setOrders(res.data.orders)
      LoadingService.hide()
    }
  })

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{_orders ? 'Siparişler' : 'Verdiğim Son Siparişler'}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Sipariş detayına gitmek için lütfen sipariş koduna tıklayınız*
          </span>
          {hundredOrders && (
            <span className='text-muted mt-1 fw-semibold fs-7'>100 siparişe kadar*</span>
          )}
        </h3>
      </div>
      <div className='card-body py-3 mt-5'>
        <div className='row justify-content-end pe-4 mb-5'>
          <div
            className='ka-tablo-sol btn-primary btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => {
              if (!tableRef.current) return
              tableRef.current.scrollLeft -= 60
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2x' />
          </div>
          <div
            className='ka-tablo-sag btn btn-sm btn-primary btn-icon btn-active-color-primary ms-2'
            onClick={() => {
              if (!tableRef.current) return
              tableRef.current.scrollLeft += 60
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div ref={tableRef as any} className='table-responsive' /*style={{ overflow: 'inherit' }}*/>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='text-nowrap min-w-150px'>Sipariş Kodu</th>
                <th className='text-nowrap min-w-120px'>Tarih</th>
                <th className='text-nowrap min-w-120px'>Saat</th>
                <th className='text-nowrap min-w-120px'>Ödeme Yöntemi</th>
                <th className='text-nowrap min-w-120px'>Teslimat Yöntemi</th>
                {/* <th className='min-w-120px'>Şirket</th> */}
                <th className='text-nowrap min-w-120px text-end'>Tutar</th>
                <th className='text-nowrap min-w-200px w-200px text-end'>Durum</th>
                {/* <th className='min-w-100px text-end'>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {
                orders.length === 0 ? (
                  <tr>
                    <td className='pt-6'>Herhangi bir sipariş kaydı bulunamadı...</td>
                  </tr>
                ) : (
                  orders.map((v, i) => (
                    <GivenOrder
                      key={i}
                      code={v.code.toUpperCase()}
                      date={moment(v.createdAt)}
                      odeme={v.payment}
                      teslimat={v.delivery}
                      total={v.total}
                      status={v.status}
                    />
                  ))
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export { RecentOrdersGiven }
