import React, { createContext, useEffect, useState } from 'react';
import { Api } from '../api';
import { LoadingService } from '../services/loading.service';

export const HomepageDataContext = createContext<any>(null);

const HomepageDataContextProvider: React.FC<any> = props => {
  const [homepageData, setHomepageData] = useState<any>(null);

  const fetchHomepageData = async () => {
    LoadingService.show()

    const cachedRes = Api.getCachedHomepageData()
    if (cachedRes) setHomepageData(cachedRes)

    LoadingService.hide()

    const res = await Api.getHomepageData()

    if (res.success) {
      setHomepageData(res.data)
    }
  }

  useEffect(() => {
    fetchHomepageData();
  }, [])

  return <HomepageDataContext.Provider value={homepageData} {...props} />;
};

export default HomepageDataContextProvider;
