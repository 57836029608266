/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useState } from 'react'
import { useDidMount } from 'rooks'
import { Api } from '../../../api'
import { HomeSlider } from '../../../components/homeSlider'
import { Product } from '../../../components/product'
import { Links } from '../../../components/widgets/links'
import { LoadingService } from '../../../services/loading.service'
// import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { AylikSatisGrafigi, AylikSiparisGrafigi } from '../../../_metronic/partials/widgets'

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'

import { Autoplay, Navigation } from 'swiper'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { HomepageDataContext } from '../../../context/HomepageDataContext'
// import

// const markalar = [
//   toAbsoluteUrl('/markalar/ecosafe.svg'),
//   toAbsoluteUrl('/markalar/woodson.svg'),
//   toAbsoluteUrl('/markalar/fleetguard.png')
// ]

const DashboardPage: FC = () => {
  // const [data, setData] = useState<any>(null)
  const [adminData, setAdminData] = useState<any>(null)

  const data = useContext(HomepageDataContext)

  useDidMount(async () => {
    const isAdmin = Api.isAdmin()

    if (isAdmin) {
      window.document.getElementById('root')?.classList.add('isadmin')
    } else {
      window.document.getElementById('root')?.classList.remove('isadmin')
    }

    LoadingService.show()

    if (isAdmin) {
      const adminRes = await Api.getHomepageAdminData()

      if (adminRes.success) {
        setAdminData(adminRes.data)
      } else {
        console.log(adminRes)
      }
    }

    LoadingService.hide()
  })

  return (
    <>
      <div className='row position-relative g-5 g-xl-10 mb-5 mb-xl-10'>
        <HomeSlider className='' />

        {!adminData ? null : (
          <>
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
              <div className={`card card-flush h-md-50 mb-5 mb-xl-10`}>
                <div className='card-header pt-5'>
                  <div className='card-title d-flex flex-column'>
                    <div className='d-flex align-items-center'>
                      <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span>

                      <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
                        {String(adminData.buAykiOnaylananKazanc).replace('.', ',')}
                      </span>
                    </div>
                    <span className='text-gray-400 pt-1 fw-semibold fs-6'>Bu Ayın Onaylanan Kazancı</span>
                  </div>
                </div>

                <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
                  <div className='d-flex flex-column content-justify-center flex-row-fluid'>
                    <div className='d-flex fw-semibold align-items-center'>
                      <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
                      <div className='text-gray-500 flex-grow-1 me-4'>Onay Bekleyen Sipariş Sayısı</div>
                      <div className='fw-bolder text-gray-700 text-xxl-end'>{adminData.buAykiOnayBekleyenSiparisSayisi}</div>
                    </div>
                    <div className='d-flex fw-semibold align-items-center mt-3'>
                      <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
                      <div className='text-gray-500 flex-grow-1 me-4'>Onay Bekleyen Siparişler</div>
                      <div className='fw-bolder text-gray-700 text-xxl-end'>${String(adminData.buAykiOnayBekleyenKazanc).replace('.', ',')}</div>
                    </div>
                    <div className='d-flex fw-semibold align-items-center my-3'>
                      <div className='bullet w-8px h-3px rounded-2 bg-primary me-3'></div>
                      <div className='text-gray-500 flex-grow-1 me-4'>Onaylanan Siparişler</div>
                      <div className='fw-bolder text-gray-700 text-xxl-end'>${String(adminData.buAykiOnaylananKazanc).replace('.', ',')}</div>
                    </div>
                    <div className='d-flex fw-semibold align-items-center'>
                      <div className='bullet w-8px h-3px rounded-2 me-3' style={{ backgroundColor: '#E4E6EF' }}></div>
                      <div className='text-gray-500 flex-grow-1 me-4'>İptal Edilen Siparişler</div>
                      <div className=' fw-bolder text-gray-700 text-xxl-end'>${String(adminData.buAykiIptalKazanc).replace('.', ',')}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card card-flush h-md-50 mb-xl-10'>
                <div className='card-header pt-5'>
                  <div className='card-title d-flex flex-column'>
                    <div className='d-flex align-items-center'>
                      <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{adminData.buAykiSiparisSayisi}</span>
                    </div>
                    <span className='text-gray-400 pt-1 fw-semibold fs-6'>Bu Ay Verilen Sipariş Sayısı</span>
                  </div>
                </div>
                <div className='card-body d-flex align-items-end pt-0'>
                  <div className='d-flex align-items-center flex-column mt-3 w-100'>
                    <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                      <span className='fw-bolder fs-6 text-dark'>
                        {adminData.buAykiBitenSiparisSayisi} tamamlanan sipariş
                      </span>
                      <span className='fw-bold fs-6 text-gray-400'>
                        {adminData.buAykiSiparisSayisi
                          ? Math.round((adminData.buAykiBitenSiparisSayisi / adminData.buAykiSiparisSayisi) * 100) + '%'
                          : null}
                      </span>
                    </div>
                    <div className='h-8px mx-3 w-100 bg-light-success rounded'>
                      <div
                        className='bg-success rounded h-8px'
                        role='progressbar'
                        style={{
                          width:
                            Math.round((adminData.buAykiBitenSiparisSayisi / adminData.buAykiSiparisSayisi) * 100) + '%'
                        }}
                        aria-valuenow={50}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
              <div className='card card-flush h-md-50 mb-5 mb-xl-10'>
                <div className='card-header pt-5'>
                  <div className='card-title d-flex flex-column'>
                    <div className='d-flex align-items-center'>
                      <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span>
                      <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
                        {adminData.bugunkiOnaylananKazanc}
                      </span>
                    </div>
                    <span className='text-gray-400 pt-1 fw-semibold fs-6'>Bu Günün Onaylanan Kazancı</span>
                  </div>
                </div>
                <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
                  <div className='d-flex flex-column content-justify-center flex-row-fluid'>
                    <div className='d-flex fw-semibold align-items-center'>
                      <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
                      <div className='text-gray-500 flex-grow-1 me-4'>Onay Bekleyen Siparişler</div>
                      <div className='fw-bolder text-gray-700 text-xxl-end'>${String(adminData.bugunkiOnayBekleyenKazanc).replace('.', ',')}</div>
                    </div>
                    <div className='d-flex fw-semibold align-items-center my-3'>
                      <div className='bullet w-8px h-3px rounded-2 bg-primary me-3'></div>
                      <div className='text-gray-500 flex-grow-1 me-4'>Onaylanan Siparişler</div>
                      <div className='fw-bolder text-gray-700 text-xxl-end'>${String(adminData.bugunkiOnaylananKazanc).replace('.', ',')}</div>
                    </div>
                    <div className='d-flex fw-semibold align-items-center'>
                      <div className='bullet w-8px h-3px rounded-2 me-3' style={{ backgroundColor: '#E4E6EF' }}></div>
                      <div className='text-gray-500 flex-grow-1 me-4'>İptal Edilen Siparişler</div>
                      <div className=' fw-bolder text-gray-700 text-xxl-end'>${String(adminData.bugunkiIptalKazanc).replace('.', ',')}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card card-flush h-md-50 mb-xl-10'>
                <div className='card-header pt-5'>
                  <div className='card-title d-flex flex-column'>
                    <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{adminData.toplamCariSayisi - 1}</span>
                    <span className='text-gray-400 pt-1 fw-semibold fs-6'>Toplam Cari Sayısı</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0'>
              <AylikSatisGrafigi
                className='card-flush overflow-hidden h-md-100'
                series={adminData.son6ayKazanc}
                axes={adminData.son6ayAdi}
              />
            </div>
            <div className='col-lg-12 mb-5 mb-xl-0 ml-auto' style={{ justifyContent: 'flex-end' }}>
              <AylikSiparisGrafigi
                className='card-flush overflow-hidden h-md-100'
                series={adminData.son6aySiparisSayisi}
                axes={adminData.son6ayAdi}
              />
            </div>
          </>
        )}

        <div className="col-12">
          <h1 className='ka-h1' style={{ marginLeft: 0, display: 'inline-flex' }}>Kampanya Ürünleri</h1>

          <div className='d-flex flex-wrap d-grid justify-content-center gap-5 gap-xxl-6 mb-3'>
            {/* <div className="ka-kampanyaslider"> */}
            {data && data.urunler ? (
              <Swiper
                // loop={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false
                }}
                freeMode={false}
                centeredSlides={false}
                navigation={true}
                pagination={false}
                modules={[Navigation, Autoplay]}
                slidesPerView={1}
                spaceBetween={20}
                breakpoints={{
                  720: {
                    slidesPerView: 2,
                    spaceBetween: 20
                  },
                  960: {
                    slidesPerView: 3,
                    spaceBetween: 24
                  },
                  1300: {
                    slidesPerView: 4,
                    spaceBetween: 24
                  },
                  1600: {
                    slidesPerView: 5,
                    spaceBetween: 24
                  }
                }}
              >
                {data.urunler
                  .filter((v: any) => v && !!v.code)
                  .map((v: any, i: number) => (
                    <SwiperSlide key={i}>
                      <Product
                        name={v.name}
                        code={v.code}
                        availability={v.availability}
                        category={v.category}
                        comment={data.urunlernot[i] ?? undefined}
                        src=''
                        price={v.price}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            ) : null}
            {/* </div> */}
          </div>

          <div className='d-flex flex-wrap d-grid justify-content-center gap-5 gap-xxl-6 mb-3 min-w-100 ka-anasayfamarka-wrapper'>
            <Swiper
              pagination={false}
              loop={false}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false
              }}
              freeMode={false}
              modules={[Navigation, Autoplay]}
              navigation={true}
              spaceBetween={24}
              slidesPerView={1}
              centeredSlides={false}
              breakpoints={{
                780: {
                  slidesPerView: 2,
                  spaceBetween: 20
                },
                1400: {
                  slidesPerView: 3,
                  spaceBetween: 20
                }
              }}
            >
              {/* {markalar.map((v: any, i: number) => ( */}
              <SwiperSlide>
                <div className='ka-anasayfamarka'>
                  <img src={toAbsoluteUrl('/markalar/ecosafe.svg')} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='ka-anasayfamarka'>
                  <img src={toAbsoluteUrl('/markalar/woodson.svg')} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='ka-anasayfamarka' style={{ transform: 'scale(1.3)' }}>
                  <img src={toAbsoluteUrl('/markalar/fleetguard.png')} alt='' />
                </div>
              </SwiperSlide>
              {/* ))} */}
            </Swiper>
          </div>
        </div>

        {!data || !data.not ? null : (
          <>
            <h1 className='ka-h1' style={{ marginTop: '48px' }}>
              Duyurular
            </h1>

            <div className='d-flex flex-wrap d-grid gap-5 gap-xxl-6 justify-content-start mt-8'>
              <div className={'card card-flush flex-row-fluid p-1 mw-100 ka-product2'}>
                <div className='card-body fs-5 fw-semibold text-dark' dangerouslySetInnerHTML={{ __html: data.not }} />
              </div>
            </div>
          </>
        )}

        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-1 mt-4'>
          <div className='ka-anasayfabilgiler-wrapper'>
            <span className='ka-anasayfabilgiler-ad'>BAREM MAKİNA SAN İTH. İHR. VE TİC. LTD. ŞTİ.</span>
            <span className='ka-anasayfabilgiler-banka'>GARANTİ BANKASI (TL):</span>
            <span className='ka-anasayfabilgiler-iban'>TR71 0006 2000 2860 0006 2993 27</span>
            <span className='ka-anasayfabilgiler-banka'>TÜRKİYE İŞ BANKASI (TL):</span>
            <span className='ka-anasayfabilgiler-iban'>TR75 0006 4000 0011 1490 3270 29</span>
            <span className='ka-anasayfabilgiler-banka'>KUVEYT TÜRK (TL):</span>
            <span className='ka-anasayfabilgiler-iban'>TR22 0020 5000 0078 4344 6039 00</span>
            {/* <img className='ka-anasayfalogo' src="/baremlogo2.png" alt=''/> */}
          </div>
          {/* <CardsWidget20
            className='h-100 mb-5 mb-xl-10'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            depodaOlanlar={data?.stokOzet[0]}
            stokMiktari={data?.stokOzet[1]}
          /> */}
        </div>

        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-1 mt-4'>
          {/* <BalanceWidget className='h-md-50 mb-5 mb-xl-10' /> */}
          {data !== null ? <Links className='h-100' linkler={data.linkler} yazilar={data.yazilar} /> : null}
        </div>
      </div>
    </>
  )
}

export { DashboardPage as DashboardWrapper }
