/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Urunler } from '../../components/widgets/urunler'

const OrderPage: FC = () => (
  <>
    <div className='row position-relative g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-xl-12'>
        <Urunler className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
  </>
)

// const DashboardWrapper: FC = () => {
//   const intl = useIntl()
//   return (
//     <>
//       <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
//       <DashboardPage />
//     </>
//   )
// }

export { OrderPage as default }
