/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  series: number[]
  axes: string[]
}

const AylikSatisGrafigi: React.FC<Props> = ({ className, series, axes }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const refreshMode = () => {
    if (!chartRef.current) return

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, series, axes, 'Net Kazanç', true))
    if (chart) chart.render()

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Aylara Göre Kazançlar</span>
          <span className='text-muted fw-semibold fs-7'>Son 6 ayın net kazanç grafiği gösterilmektedir</span>
        </h3>

        {/* <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
            id='kt_charts_widget_3_year_btn'
          >
            Year
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
            id='kt_charts_widget_3_month_btn'
          >
            Month
          </a>

          <a className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4' id='kt_charts_widget_3_week_btn'>
            Week
          </a>
        </div> */}
      </div>

      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '350px' }}></div>
      </div>
    </div>
  )
}

const AylikSiparisGrafigi: React.FC<Props> = ({ className, series, axes }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const refreshMode = () => {
    if (!chartRef.current) return

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, series, axes, 'Sipariş Sayısı', false))
    if (chart) chart.render()

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Aylara Göre Siparişler</span>
          <span className='text-muted fw-semibold fs-7'>Son 6 ayın sipariş grafiği gösterilmektedir</span>
        </h3>
      </div>

      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '350px' }}></div>
      </div>
    </div>
  )
}

export { AylikSatisGrafigi, AylikSiparisGrafigi }

function getChartOptions(height: number, series: number[], axes: string[], seriesName = 'Net Kazanç', useFormatter: boolean): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-info')
  const lightColor = getCSSVariableValue('--kt-info-light')

  return {
    series: [
      {
        name: seriesName,
        data: series
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'solid',
      opacity: 1
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor]
    },
    xaxis: {
      categories: axes,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px'
        }
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3
        }
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px'
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: '12px'
      },
      y: {
        formatter: function (val) {
          return useFormatter ? `$${val}`.replace('.', ',') : `${val}`
        }
      }
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3
    }
  }
}
