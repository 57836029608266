import axios from 'axios'
// @ts-ignore
// import tcmbdoviz from 'tcmb-doviz'

export const API_URL = process.env.REACT_APP_BAREM_BACKEND
export const PIC_URL = process.env.REACT_APP_BAREM_PICTURES

function encodeQueryData(data: any) {
  const ret = []
  for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
  return ret.join('&')
}

export class Api {
  public static discountTable: Record<string, number> | null = null

  public static createProductUrl(code: string) {
    return `${PIC_URL}/${code.toUpperCase()}.webp`
  }

  public static async odemeYap(
    CardHolderFullName: string,
    CardNumber: string,
    ExpMonth: string,
    ExpYear: string,
    CvcNumber: string,
    Amount: number,
    Amount2: number,
    InstallmentNumber: number
  ) {
    const udata = Api.getLocalUserData()

    return (
      await axios.get(`${API_URL}/moka/odeme`, {
        params: {
          code: udata.code,
          token: udata.token,
          CardHolderFullName,
          CardNumber,
          ExpMonth,
          ExpYear,
          CvcNumber,
          Amount,
          Amount2,
          InstallmentNumber
        }
      })
    ).data
  }

  public static async mokaEskiler() {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/moka/eskiler`, {
        params: { code: udata.code, token: udata.token }
      })
    ).data
  }

  public static async mokaTaksitler(miktar: number, bin: string) {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/moka/taksit`, {
        params: { code: udata.code, token: udata.token, miktar, bin }
      })
    ).data
  }

  public static async mokaUpdateStatus(OtherTrxCode: string, Status: string) {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/moka/update`, {
        params: { code: udata.code, token: udata.token, OtherTrxCode, Status }
      })
    ).data
  }

  public static createHareketlerExcelDownloadLink(user?: any) {
    const udata = Api.getLocalUserData()
    const queryData = encodeQueryData({
      code: user ? user.code : udata.code,
      token: user ? user.token : udata.token
    })
    return API_URL + '/user/hareketlerindir?' + queryData
  }

  public static normalizeSearchStr(kod: string) {
    return (
      kod
        .replace(/[^0-9a-z]/gi, '')
        // .replaceAll(',', '')
        // .replaceAll('/', '')
        // .replaceAll(' ', '')
        .toUpperCase()
        .trim()
    )
  }

  public static async getKampanyaUrunler() {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/kampanya-urunler`, {
        params: { code: udata.code, token: udata.token }
      })
    ).data
  }

  public static hasDiscountedForCode(urunKodu: string) {
    return !!Api.getDiscountedForCode(urunKodu)
  }

  public static getDiscountedForCode(urunKodu: string): undefined | number {
    const udata = Api.getLocalUserData()
    if (!udata || !udata.specialDiscounts || !udata.specialDiscounts.data) return undefined
    return udata.specialDiscounts.data[urunKodu]
  }

  public static async getKur(): Promise<null | any> {
    // return (
    //   await axios.get(`${API_URL}/kur`, {
    //     params: {}
    //   })
    // ).data
    return 0
  }

  public static async requestPaymentURL(
    name: string,
    no: string,
    month: number,
    year: number,
    cvv: number
  ): Promise<null | any> {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/order/req`, {
        params: {
          code: udata.code,
          token: udata.token,
          name,
          no,
          month,
          year,
          cvv
        }
      })
    ).data
  }

  public static async postExcelData(
    cariler: string,
    iskontolar: string,
    muadiller: string,
    ozel: string,
    mail: string,
    vadesigecen: string
  ) {
    const udata = Api.getLocalUserData()
    return (
      await axios.post(`${API_URL}/excel`, {
        params: { code: udata.code, token: udata.token, cariler, iskontolar, muadiller, ozel, mail, vadesigecen }
      })
    ).data
  }

  public static async updateOrder(siparisCode: string, status: string) {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/order/manage/update`, {
        params: { code: udata.code, token: udata.token, siparisCode, status }
      })
    ).data
  }

  public static async regenPass(cariCode: string, passCount: number) {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/user/updatepass`, {
        params: { code: udata.code, token: udata.token, cariCode, passCount }
      })
    ).data
  }

  public static async setHomepageData(
    yazilar: string,
    linkler: string,
    urunler: string,
    urunlernot: string,
    not: string,
    musteriAcKapa: boolean
  ) {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/anasayfa/update`, {
        params: { code: udata.code, token: udata.token, yazilar, linkler, urunler, urunlernot, not, musteriAcKapa }
      })
    ).data
  }

  public static getCachedHomepageData(): null | any {
    const d = localStorage.getItem('__homepagedatacache')
    if (!d) return null

    return JSON.parse(d)
  }

  public static async getHomepageData() {
    const data = (
      await axios.get(`${API_URL}/anasayfa/get`, {
        params: {}
      })
    ).data

    if (data && data.success) localStorage.setItem('__homepagedatacache', JSON.stringify(data.data))

    return data
  }

  public static async getHomepageAdminData() {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/anasayfa/details`, {
        params: { code: udata.code, token: udata.token }
      })
    ).data
  }

  public static async retrieveOrder(siparisCode: string) {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/order/get`, {
        params: { code: udata.code, token: udata.token, siparisCode }
      })
    ).data
  }

  public static async sendMail(subject: string, message: string) {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/mail/send`, {
        params: { code: udata.code, token: udata.token, subject, message }
      })
    ).data
  }

  public static isAdmin(): boolean {
    let udata: any = {}

    try {
      udata = Api.getLocalUserData()
    } catch {
      return false
    }

    return udata.type === 'admin'
  }

  public static getSpecificPrice(code: string, category: string, price: number, count: number = 1): number {
    const specific = Api.getDiscountedForCode(code)
    if (specific) return +parseFloat((specific * count).toFixed(3))
    return +parseFloat((price * (1 - Api.getDiscount(category)) * count).toFixed(3))
  }

  public static getDiscount(forCategory: any, forRatios: string | null = '') {
    let udata: any = {}

    try {
      udata = Api.getLocalUserData()
    } catch {
      return 0
    }

    const isAdmin = udata.type === 'admin'

    if (isAdmin && !forRatios) return 0

    let table2use: any = Api.discountTable

    if (forRatios || !Api.discountTable) {
      const s = forRatios ? forRatios.split(',') : udata.discounts.split(',')

      table2use = {
        fleetguard: parseFloat(s[0] ?? '0'),
        woodson: parseFloat(s[1] ?? '0'),
        sakura: parseFloat(s[2] ?? '0'),
        ecosafe: parseFloat(s[3] ?? '0'),
        cat: parseFloat(s[4] ?? '0'),
        __other: parseFloat(s[5] ?? '0')
      }

      if (!forRatios) Api.discountTable = table2use
    }

    const c = table2use[forCategory.toLowerCase()]

    if (forCategory && c !== undefined) {
      return c
    } else {
      return table2use.__other
    }
  }

  public static async getUser(userCode: string) {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/user/get`, {
        params: { code: udata.code, token: udata.token, userCode }
      })
    ).data
  }

  public static async listUsers() {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/user/list`, {
        params: { code: udata.code, token: udata.token }
      })
    ).data
  }

  public static async listOrdersTaken() {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/order/manage/list`, {
        params: { code: udata.code, token: udata.token }
      })
    ).data
  }

  public static async getHareketler() {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/user/hareketler`, {
        params: { code: udata.code, token: udata.token }
      })
    ).data
  }

  public static async listOrders() {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/order/list`, {
        params: { code: udata.code, token: udata.token }
      })
    ).data
  }

  public static async createOrder(data: string, payment: string, delivery: string, note: string = '' /*, card?: any*/) {
    const udata = Api.getLocalUserData()
    return (
      await axios.post(`${API_URL}/order/create`, {
        params: {
          code: udata.code,
          token: udata.token,
          data,
          payment,
          delivery,
          note
          // card
        }
      })
    ).data
  }

  public static async listBasket() {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/basket/list`, {
        params: { code: udata.code, token: udata.token }
      })
    ).data
  }

  public static async updateBasket(list: string, count: string) {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/basket/update`, {
        params: { code: udata.code, token: udata.token, list, count }
      })
    ).data
  }

  public static async searchProduct(productCode: string) {
    const udata = Api.getLocalUserData()
    return (
      await axios.get(`${API_URL}/product/search`, {
        params: { code: udata.code, token: udata.token, productCode }
      })
    ).data
  }

  public static async refresh(code: string, token: string) {
    return (
      await axios.get(`${API_URL}/auth/refresh`, {
        params: { code, token }
      })
    ).data
  }

  public static async auth(code: string, pass: string) {
    return (
      await axios.get(`${API_URL}/auth`, {
        params: { code, pass }
      })
    ).data
  }

  public static async autha(code: string, pass: string) {
    return (
      await axios.get(`${API_URL}/auth`, {
        params: { code, pass }
      })
    ).data
  }

  public static getLocalUserData() {
    try {
      return JSON.parse(String(localStorage.getItem('__userdata') ?? '')).data
    } catch {
      window.location.reload()
      return null
    }
  }

  public static data2themedata(_data: any) {
    return {
      id: _data.code,
      username: _data.code,
      password: undefined,
      email: _data.code,
      first_name: _data.name,
      last_name: '',
      fullname: _data.name,
      isNew: true,
      data: _data
    }
  }
}
