import { MenuItem } from './MenuItem'
import { Api } from '../../../../../api'
import { useEffect, useState } from 'react'

export function MenuInner() {
  const [ordersCount, setOrderCounts] = useState(0)
  const [onlineOdemeCount, setOnlineOdemeCounts] = useState(0)
  const [kampanyaUrunCount, setKampanyaUrunCounts] = useState(0)
  let udata: any = {}

  const fetchOrdersCount = async () => {
    const res = await Api.listOrdersTaken()

    if (res.success) {
      const orders = res.data.orders.filter(({ status }: any) => status === 'Onay Bekliyor')

      setOrderCounts(orders.length)
    }
  }

  const fetchOnlineOdemeCount = async () => {
    const res = await Api.mokaEskiler()

    if (res.success) {
      const odemeler = res.data.filter(({ Status }: any) => Status === 'await')

      setOnlineOdemeCounts(odemeler.length)
    }
  }

  const fetchKampanyaUrunCount = async () => {
    const res = await Api.getKampanyaUrunler()

    if (res.success) {
      const urunler = res.products

      setKampanyaUrunCounts(urunler.length)
    }
  }

  useEffect(() => {
    fetchOrdersCount()
    fetchOnlineOdemeCount()
    fetchKampanyaUrunCount()
  }, [])

  useEffect(() => {
    window.addEventListener('ordersUpdated', fetchOrdersCount)
    window.addEventListener('odemelerUpdated', fetchOnlineOdemeCount)

    return () => {
      window.removeEventListener('ordersUpdated', fetchOrdersCount)
      window.removeEventListener('odemelerUpdated', fetchOnlineOdemeCount)
    }
  })

  try {
    udata = Api.getLocalUserData()
  } catch {
    return null
  }

  return (
    <>
      {udata.type === 'user' ? (
        <>
          <MenuItem title='Anasayfa' to='/anasayfa' />
          {/* <MenuItem title='Hesabım' to='/hesabim' /> */}
          <MenuItem title='Cari Hareketler' to='/hareketler' />
          <MenuItem title='Online Ödeme' to='/onlineodeme' />
          <MenuItem title='Siparişlerim' to='/siparisler' />
          <MenuItem title='Kampanya Ürünleri' to='/kampanya-urunleri' badge={kampanyaUrunCount} />
          <MenuItem title='İletişim' to='/iletisim' />
        </>
      ) : null}
      {udata.type === 'admin' ? (
        <>
          <MenuItem title='Anasayfa' to='/anasayfa' />
          <MenuItem title='Anasayfa Yönetim' to='/yonet/anasayfa' />
          <MenuItem title='Exceller' to='/yonet/excel' />
          <MenuItem title='Siparişler' to='/yonet/siparisler' badge={ordersCount} />
          <MenuItem title='Cariler' to='/yonet/cariler' />
          <MenuItem title='Online Ödemeler' to='/yonet/odemeler' badge={onlineOdemeCount} />
        </>
      ) : null}
    </>
  )
}
