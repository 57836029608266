import { lazy, FC, Suspense, useContext } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { OrderPage } from '../pages/OrderPage'
import UrunlerPage from '../pages/UrunlerPage'
import { CartPage } from '../pages/CartPage'
import { ContactPage } from '../pages/ContactPage'
import { HomepageDataContext } from '../../context/HomepageDataContext'
import { useAuth } from '../modules/auth'
import KampanyaUrunleriPage from '../pages/KampanyaUrunleriPage'

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const HareketlerPage = lazy(() => import('../pages/HareketPage'))
  const OnlineOdemePage = lazy(() => import('../pages/OnlineOdemePage'))
  const ManageOdemePage = lazy(() => import('../pages/ManageOdemePage'))
  const ManageHomepagePage = lazy(() => import('../pages/ManageHomepagePage'))
  const ManageExcelPage = lazy(() => import('../pages/ManageExcelPage'))
  const ManageClientsPage = lazy(() => import('../pages/ManageClientsPage'))
  const ManageOrdersPage = lazy(() => import('../pages/ManageOrderPage'))
  const FAQPage = lazy(() => import('../pages/FAQ'))
  const ManageCustomerPage = lazy(() => import('../pages/ManageCustomer'))
  const InvoicePage = lazy(() => import('../pages/InvoicePage'))

  const homepageData = useContext(HomepageDataContext)
  const { currentUser } = useAuth()

  if (!homepageData) {
    return null
  }

  if (currentUser?.data.type !== 'admin' && homepageData.musteriAcKapa !== false && window.location.pathname !== '/hata/404') {
    const splash = document.querySelector('#splash-screen') as HTMLElement
    splash.style.display = 'flex'
  } else {
    const splash = document.querySelector('#splash-screen') as HTMLElement
    splash.style.display = 'none'
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/anasayfa' />} />
        <Route path='kampanya-urunleri' element={<KampanyaUrunleriPage />} />
        <Route path='anasayfa' element={<DashboardWrapper />} />
        <Route path='siparisler' element={<OrderPage />} />
        <Route path='urunler' element={<UrunlerPage />} />
        <Route path='sepet' element={<CartPage />} />
        <Route path='iletisim' element={<ContactPage />} />

        <Route
          path='hesabim/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='hareketler'
          element={
            <SuspensedView>
              <HareketlerPage />
            </SuspensedView>
          }
        />
        <Route
          path='onlineodeme'
          element={
            <SuspensedView>
              <OnlineOdemePage />
            </SuspensedView>
          }
        />
        <Route
          path='sikca-sorulan-sorular'
          element={
            <SuspensedView>
              <FAQPage />
            </SuspensedView>
          }
        />
        <Route
          path='siparis/*'
          element={
            <SuspensedView>
              <InvoicePage />
            </SuspensedView>
          }
        />
        <Route
          path='yonet/anasayfa'
          element={
            <SuspensedView>
              <ManageHomepagePage />
            </SuspensedView>
          }
        />
        <Route
          path='yonet/excel'
          element={
            <SuspensedView>
              <ManageExcelPage />
            </SuspensedView>
          }
        />
        <Route
          path='yonet/siparisler'
          element={
            <SuspensedView>
              <ManageOrdersPage />
            </SuspensedView>
          }
        />
        <Route
          path='yonet/odemeler'
          element={
            <SuspensedView>
              <ManageOdemePage />
            </SuspensedView>
          }
        />
        <Route
          path='yonet/cariler'
          element={
            <SuspensedView>
              <ManageClientsPage />
            </SuspensedView>
          }
        />
        <Route
          path='yonet/cari/*'
          element={
            <SuspensedView>
              <ManageCustomerPage />
            </SuspensedView>
          }
        />
        {/* PAGE NOT FOUND */}
        <Route path='*' element={<Navigate to='/hata/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
