import React, { FC } from 'react'
import { useDidMount } from 'rooks'
import { useService } from '../../../../hooks/use-service'
import { BasketService, BasketServiceObject } from '../../../../services/basket.service'
import { KTSVG } from '../../../helpers'

const Cart: FC = () => {
  const basket = useService<BasketServiceObject>(BasketService, { items: [] })
  const count = basket.items.length


  useDidMount(() => {
    BasketService.downloadAndUpdateBasketData()
  })

  return (
    <>
      <a
        id='kt_header_search'
        className='d-flex align-items-stretch'
        href='/sepet'
        // data-kt-search-keypress='true'
        // data-kt-search-min-length='2'
        // data-kt-search-enter='enter'
        // data-kt-search-layout='menu'
        // data-kt-menu-trigger='auto'
        // data-kt-menu-overflow='false'
        // data-kt-menu-permanent='true'
        // data-kt-menu-placement='bottom-end'
        // ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
          style={{
            position: 'relative'
          }}
        >
          <div /* w-30px w-md-40px */ className='w-auto px-4 btn btn-icon btn-active-light-primary h-40px ms-2'>
            <span className='fw-bold me-2 d-none d-lg-inline'>Sepetim </span>
            <KTSVG path='/sepet-icon3.svg' className='svg-icon-1' />
            {/* <span className='pulse-ring w-45px h-45px'></span> */}
          </div>
          {count !== 0 ? <div className='ka-total-items-count'>{count}</div> : null}
        </div>
      </a>
    </>
  )
}

export { Cart }
