import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const Error404: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Olamaz!</h1>

      <div className='fw-semibold fs-6 text-gray-500 mb-7'>
        Aradığınız sayfa bulunamadı. Bu sayfa silinmiş olabilir, yetkiniz olmayabilir veya hiç var olmadı.
      </div>

      <div className='mb-3'>
        <img src={toAbsoluteUrl('/media/auth/404-error.png')} className='mw-100 mh-300px theme-light-show' alt='' />
        <img src={toAbsoluteUrl('/media/auth/404-error-dark.png')} className='mw-100 mh-300px theme-dark-show' alt='' />
      </div>

      <div className='mb-0'>
        <Link to='/anasayfa' className='btn btn-sm btn-primary'>
          Anasayfaya Dön
        </Link>
      </div>
    </>
  )
}

export { Error404 }
