/* eslint-disable jsx-a11y/anchor-is-valid */
import type { Merge } from 'type-fest'
import { FC, useRef, useState } from 'react'
import { useService } from '../../hooks/use-service'
import { BasketService, BasketServiceObject } from '../../services/basket.service'
import { useDidMount } from 'rooks'
import { KTSVG } from '../../_metronic/helpers'
import { LoadingService } from '../../services/loading.service'
import { Api } from '../../api'
import InputMask from 'react-input-mask'

export interface CartProductProps {
  name: string
  code: string
  category: string
  src?: string
  price: string | number
  changeCount: (newval: number) => void
  updateCheckness: (i: number, v: boolean) => void
  checked: boolean
  ind: number
}

const CartItem = ({
  name,
  code,
  category,
  price,
  count,
  changeCount,
  updateCheckness,
  checked,
  ind
}: Merge<CartProductProps, { count: number }>) => {
  const inputMiktarRef = useRef<HTMLInputElement>()

  const precisePer = Api.getSpecificPrice(code, category, parseFloat(String(price)), 1)
  const precise = Api.getSpecificPrice(code, category, parseFloat(String(price)), parseInt(String(count)))

  function handleMiktarChange() {
    let newMiktar = parseInt(inputMiktarRef.current?.value ?? '')

    if (newMiktar > 9999) {
      alert("Sepetteki ürün adeti 9999'ü geçemez.")
      newMiktar = 9999
    }

    if (!inputMiktarRef.current || !newMiktar || newMiktar <= 0) {
      inputMiktarRef.current!.value = count.toString()
      return
    }

    inputMiktarRef.current.value = newMiktar.toString()

    changeCount(newMiktar)
  }

  const picUrl = Api.createProductUrl(code)

  return (
    <tr data-kt-pos-element='item' data-kt-pos-item-price={33}>
      <td className="ka-sepet-pic-td">
        <a href={picUrl} target='_blank' rel="noreferrer"><img src={picUrl} alt="" /></a>
      </td>
      <td className=''>
        <div className='form-check form-check-custom form-check-solid me-2'>
          <input
            className='form-check-input h-30px w-30px'
            type='checkbox'
            checked={checked}
            onChange={() => {
              updateCheckness(ind, !checked)
            }}
          />
        </div>
      </td>
      <td className='pe-0'>
        <div className='d-flex align-items-center'>
          {/* <img src={src} className='w-50px h-50px rounded-3 me-3' alt='' /> */}
          <div>
            <span className='fw-bold text-gray-800 fs-6 me-1'>{code}</span>
            <br />
            <span className='fw-semibold text-muted fs-6 me-1'>
              {name} ({category.toUpperCase()})
            </span>
          </div>
        </div>
      </td>
      <td className='pe-0'>
        <div
          className='position-relative d-flex align-items-center'
          data-kt-dialer='true'
          data-kt-dialer-min={1}
          data-kt-dialer-max={10}
          data-kt-dialer-step={1}
          data-kt-dialer-decimals={0}
        >
          <button
            type='button'
            className='btn btn-icon btn-sm btn-light btn-icon-gray-400'
            // data-kt-dialer-control='decrease'
            onClick={() => {
              if (count === 1 && !window.confirm('Bu ürün sepetinizden çıkarılacaktır, devam edilsin mi?')) return
              changeCount(count - 1)
              if (count - 1 > 0) inputMiktarRef.current!.value = (count - 1).toString()
              // inputMiktarRef.current!.value = count - 1
            }}
          >
            <span className='svg-icon svg-icon-3x'>
              <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect x={6} y={11} width={12} height={2} rx={1} fill='currentColor' />
              </svg>
            </span>
          </button>
          <span className='fs-3 fw-bold text-gray-800 ps-2'>(</span>
          <input
            ref={inputMiktarRef as any}
            type='text'
            className='form-control border-0 text-center px-0 fs-3 fw-bold text-gray-800 w-50px'
            data-kt-dialer-control='input'
            placeholder=''
            name='manageBudget'
            defaultValue={count}
            onChange={handleMiktarChange}
          />
          <span className='fs-3 fw-bold text-gray-800 pe-2'>)</span>
          <button
            type='button'
            className='btn btn-icon btn-sm btn-light btn-icon-gray-400'
            // data-kt-dialer-control='increase'
            onClick={() => {
              changeCount(count + 1)
              if (count + 1 > 9999) return
              inputMiktarRef.current!.value = (count + 1).toString()
            }}
          >
            <span className='svg-icon svg-icon-3x'>
              <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect
                  opacity='0.5'
                  x={11}
                  y={18}
                  width={12}
                  height={2}
                  rx={1}
                  transform='rotate(-90 11 18)'
                  fill='currentColor'
                />
                <rect x={6} y={11} width={12} height={2} rx={1} fill='currentColor' />
              </svg>
            </span>
          </button>
        </div>
      </td>
      <td className='text-end'>
        <span className='fw-bold text-primary fs-3 text-nowrap'>
          <span>${precise.toFixed(3).split('.').join(',')}</span>
        </span>
        <br />
        <span className='fw-bold text-primary opacity-50 fs-5 text-nowrap'>
          <span>${precisePer.toFixed(3).split('.').join(',')}</span>
        </span>
      </td>
    </tr>
  )
}

const CartPage: FC = function CartPage() {
  const basket = useService<BasketServiceObject>(BasketService, { items: [] })

  const noteRef = useRef<any>()

  const inputCardNameRef = useRef<HTMLInputElement>()
  const inputCardNoRef = useRef<HTMLInputElement>()
  const inputCardMonthRef = useRef<HTMLSelectElement>()
  const inputCardYearRef = useRef<HTMLSelectElement>()
  const inputCardCVVRef = useRef<HTMLInputElement>()

  const [odeme, setOdeme] = useState('cari') // cari, online, ''
  const [teslimat, setTeslimat] = useState('') // kargo, arac, kendim, ''
  const [checkness, setCheckness] = useState<boolean[]>([])
  const [didCheckness, setDidCheckness] = useState<boolean>(false)
  const [kur, setKur] = useState(0)

  useDidMount(async () => {
    BasketService.downloadAndUpdateBasketData()

    const kurdata = await Api.getKur()
    if (kurdata && kurdata.success) {
      setKur(kurdata.data.USDTRY)
    } else {
      console.log('Kur çekilemedi')
    }
  })

  if (!didCheckness && basket.items.length !== 0) {
    setCheckness(basket.items.map(() => true))
    setDidCheckness(true)
  }

  const the_count = basket.items.length

  let total_price = 0
  for (const i in basket.items) {
    const item = basket.items[i]
    const totalpr = Api.getSpecificPrice(item.code, item.category, item.itemPrice, parseInt(item.count))
    const checked = checkness[i] ?? true
    if (!checked) continue
    total_price = +parseFloat((total_price + totalpr).toFixed(3))
  }

  function updateCheckness(i: number, v: boolean) {
    setCheckness(checkness.map((_v, _i) => (_i === i ? v : _v)))
  }

  async function siparisVer() {
    const not = noteRef.current?.value
    const urunler = basket.items

    if (urunler.length === 0 || total_price === 0) {
      return alert('Sepetinizde ürün bulunmamaktadır!')
    }

    if (!odeme) {
      return alert('Lütfen bir ödeme yöntemi seçin.')
    }

    if (odeme === 'online') {
      return alert('Online ödeme henüz desteklenmemektedir.')
    }

    if (!teslimat) {
      return alert('Lütfen bir teslimat yöntemi seçin.')
    }

    // const card: any = {
    //   name: inputCardNameRef.current?.value ?? null,
    //   no: inputCardNoRef.current?.value ?? null,
    //   month: inputCardMonthRef.current?.value ?? null,
    //   year: inputCardYearRef.current?.value ?? null,
    //   cvv: inputCardCVVRef.current?.value ?? null
    // }

    // if (!card.name || !card.no || !card.month || !card.year || !card.cvv) {
    //   return alert('Lütfen kart bilgilerini eksiksiz bir şekilde doldurunuz.')
    // }

    // if (!/^-?\d+$/.test(card.cvv)) return alert('Geçersiz bir CVC kodu girdiniz.')

    // card.cvv = parseInt(card.cvv)
    // card.month = parseInt(card.month)
    // card.year = parseInt(card.year)

    if (window.confirm('Siparişiniz onaya gönderilecektir, kabul ediyor musunuz?')) {
      LoadingService.show()
      const _checkedData = []
      const uncheckedItems = []

      for (const i in urunler) {
        const _item = urunler[i]
        const v = [_item.code, parseInt(String(_item.count))]
        if (checkness[i] !== true) {
          uncheckedItems.push(_item)
        } else {
          _checkedData.push(v)
        }
      }

      // for (const b of urunler) {
      //   if (b.code === )
      // }
      // const s = await Api.requestPaymentURL(card.name, card.no, card.month, card.year, card.cvv)
      // console.log(s)

      const res = await Api.createOrder(JSON.stringify(_checkedData), odeme, teslimat, not)

      if (res.success) {
        const siparisCode = (res.data.code as string).toUpperCase()
        await BasketService.uploadBasketData(uncheckedItems)
        LoadingService.hide()
        window.location.replace('/siparis/' + siparisCode)
      } else {
        alert('Bir hata oluştu: ' + res.error?.message ?? '')
        LoadingService.hide()
      }
    }
  }

  return (
    <div className='flex-row-auto'>
      <div className='card card-flush bg-body' id='kt_pos_form'>
        <div className='card-header pt-5'>
          <h3 className='card-title fw-bold text-gray-800 fs-1'>Sepetim</h3>
          <div className='card-toolbar'>
            <span
              style={{
                pointerEvents: the_count === 0 ? 'none' : 'auto'
              }}
              role='button'
              className='btn btn-light-primary fs-6 fw-bold py-4'
              onClick={() => {
                if (!window.confirm('Sepetteki tüm ürünler temizlenecektir, devam edilsin mi?')) return
                BasketService.clearBasket()
              }}
            >
              Hepsini Temizle
            </span>
          </div>
        </div>
        <div className='card-body pt-0'>
          <div className='table-responsive mb-8'>
            <table className='table align-middle gs-0 gy-4 my-0'>
              <thead>
                <tr>
                  <th className='ka-sepet-pic-th'></th>
                  <th className='w-40px'></th>
                  <th className='min-w-175px' />
                  {/* <th className='w-100px' /> */}
                  <th className='w-125px' />
                  <th className='w-100px' />
                </tr>
              </thead>
              <tbody>
                {the_count === 0 ? (
                  <tr className='fs-4 text-muted'>
                    <td className='text-left text-nowrap'>Sepetinizde ürün bulunmamaktadır</td>
                  </tr>
                ) : null}
                {basket.items.map((v, i: number) => (
                  <CartItem
                    updateCheckness={updateCheckness}
                    checked={checkness[i] ?? true}
                    ind={i}
                    name={v.name}
                    code={v.code}
                    price={v.itemPrice}
                    category={v.category}
                    count={parseInt(v.count)}
                    src=''
                    key={i}
                    changeCount={(newCount) => {
                      if (newCount < 0) return
                      const the_items = basket.items

                      if (newCount === 0) {
                        const new_items = []
                        for (const item of the_items) {
                          if (item.code !== v.code) new_items.push(item)
                        }
                        BasketService.uploadBasketData(new_items)
                        return
                      } else {
                        if (newCount > 9999) {
                          alert("Sepetteki ürün adeti 9999'ü geçemez.")
                          return
                        }

                        for (const item of the_items) {
                          if (item.code === v.code) {
                            item.count = String(newCount)
                          }
                        }
                      }
                      BasketService.uploadBasketData(the_items)
                    }}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div
            className='ka-sepet-yesilkutu d-flex flex-stack bg-success rounded-3 p-6 mb-10 mw-50'
            style={{ marginLeft: 'calc(50% + 16px)' }}
          >
            <div className='fs-6 fw-bold text-white'>
              <span className='d-block lh-1 mb-4'>Ara Toplam</span>
              {/* <span className="d-block mb-2">Discounts</span> */}
              <span className='d-block mb-9'>KDV (%20)</span>
              <span className='d-block fs-1 lh-1 mb-2'>Toplam</span>
              <span className='d-block fs-1 lh-1'>&ensp;</span>
              <span className='d-block fs-1 lh-1'>&ensp;</span>
            </div>
            <div className='fs-6 fw-bold text-white text-end'>
              <span className='d-block lh-1 mb-4' data-kt-pos-element='total'>
                ${total_price.toFixed(3).split('.').join(',')}
              </span>
              <span className='d-block lh-1 mb-9' data-kt-pos-element='tax'>
                ${(total_price * 0.20).toFixed(3).split('.').join(',')}
              </span>
              {/* <span className="d-block mb-2" data-kt-pos-element="discount">-$8.00</span> */}
              {/* <span className='d-block mb-9' data-kt-pos-element='tax'>
                13.12 TL
              </span> */}
              <span className='d-block fs-1 lh-1 mb-2' data-kt-pos-element='grant-total'>
                ${(total_price * 1.20).toFixed(3).split('.').join(',')}
              </span>
              {/* <span className='d-block fs-1 lh-1 opacity-75 mb-2' data-kt-pos-element='grant-totaltl'>
                = {(total_price * 1.20 * kur).toFixed(3).split('.').join(',')}₺
              </span> */}
              {/* <span className='d-block fs-3 lh-1 opacity-75' data-kt-pos-element='grant-totaltl'>
                {`$1 = ${kur}₺`.split('.').join(',')}
              </span> */}
            </div>
          </div>
          {the_count === 0 ? null : (
            <>
              <div className='m-0'>
                <h1 className='fw-bold text-gray-800 mb-8'>Ödeme Yöntemi</h1>
                <div
                  className='d-flex flex-equal gap-5 gap-xxl-9 px-0 mb-12'
                  data-kt-buttons='true'
                  data-kt-buttons-target='[data-kt-button]'
                  data-kt-initialized={1}
                >
                  {/* <label
                    className={
                      'btn bg-light btn-color-gray-600 border border-3 border-gray-100 w-100 px-4 border-active-primary btn-active-light-primary btn-active-text-gray-800' +
                      (odeme === 'online' ? ' ka-sepet-active' : '')
                    }
                    data-kt-button='true'
                    onClick={() => {
                      setOdeme('online')
                    }}
                  >
                    <input className='btn-check' type='radio' name='method' defaultValue={0} />
                    <KTSVG path='/media/icons/duotune/finance/fin002.svg' className='ka-sepet-icon' />
                    <span className='fs-4 fw-bold d-block mt-3'>Online Ödeme</span>
                  </label> */}
                  <label
                    className={
                      'btn bg-light btn-color-gray-600 border border-3 border-gray-100 w-100 px-4 border-active-primary btn-active-light-primary btn-active-text-gray-800' +
                      (odeme === 'cari' ? ' ka-sepet-active' : '')
                    }
                    data-kt-button='true'
                    onClick={() => {
                      setOdeme('cari')
                    }}
                  >
                    <input className='btn-check' type='radio' name='method' defaultValue={0} />
                    {/* <i className='fonticon-cash-payment fs-2hx mb-2 pe-0' /> */}
                    <KTSVG path='/media/icons/duotune/communication/com006.svg' className='ka-sepet-icon' />
                    <span className='fs-4 fw-bold d-block mt-3'>{Api.isAdmin() ? 'Sınırsız Admin Bakiyesi ile ödeme' : 'Cari Bakiye ile Ödeme'}</span>
                  </label>
                </div>
              </div>
              {true ? null : (
                <div
                  className='card card-flush pt-4 mb-12 ka-odemebilgileri'
                  style={{ outline: '2.5px solid #0095e8' }}
                >
                  <div className='card-header' style={{ minHeight: '50px' }}>
                    <div className='card-title'>
                      <h2>Ödeme Bigileri</h2>
                    </div>
                  </div>
                  <div className='card-body pt-6 pb-2'>
                    <div className='form fv-plugins-bootstrap5 fv-plugins-framework'>
                      <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span className=''>Kart Üzerindeki İsim</span>
                        </label>
                        <input
                          ref={inputCardNameRef as any}
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='Kart Üzerindeki İsim'
                        />
                      </div>
                      <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid'>
                        <label className='fs-6 fw-semibold form-label mb-2'>Kart Numarası</label>
                        <div className='position-relative'>
                          <InputMask
                            ref={inputCardNoRef as any}
                            mask='9999 9999 9999 9999'
                            maskChar='_'
                            placeholder='____ ____ ____ ____'
                            // defaultValue='5127 5411 2222 3332'
                            alwaysShowMask={false}
                            // type="text"
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='row mb-10'>
                        <div className='col-md-8 fv-row'>
                          <label className='fs-6 fw-semibold form-label mb-2'>Son Kullanım Tarihi</label>
                          <div className='row fv-row fv-plugins-icon-container'>
                            <div className='col-6'>
                              <select
                                ref={inputCardMonthRef as any}
                                className='form-select form-select-solid'
                                aria-label='Ay'
                                defaultValue='12'
                              >
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='3'>4</option>
                                <option value='3'>5</option>
                                <option value='3'>6</option>
                                <option value='3'>7</option>
                                <option value='3'>8</option>
                                <option value='3'>9</option>
                                <option value='3'>10</option>
                                <option value='3'>11</option>
                                <option value='3'>12</option>
                              </select>
                            </div>
                            <div className='col-6'>
                              <select
                                ref={inputCardYearRef as any}
                                className='form-select form-select-solid'
                                aria-label='Yıl'
                                defaultValue='2022'
                              >
                                <option value='2022'>2022</option>
                                <option value='2023'>2023</option>
                                <option value='2024'>2024</option>
                                <option value='2025'>2025</option>
                                <option value='2026'>2026</option>
                                <option value='2027'>2027</option>
                                <option value='2028'>2028</option>
                                <option value='2029'>2029</option>
                                <option value='2030'>2030</option>
                                <option value='2031'>2031</option>
                                <option value='2032'>2032</option>
                                <option value='2033'>2033</option>
                                <option value='2034'>2034</option>
                                <option value='2035'>2035</option>
                                <option value='2036'>2036</option>
                                <option value='2037'>2037</option>
                                <option value='2038'>2038</option>
                                <option value='2039'>2039</option>
                                <option value='2040'>2040</option>
                                <option value='2041'>2041</option>
                                <option value='2042'>2042</option>
                                <option value='2043'>2043</option>
                                <option value='2044'>2044</option>
                                <option value='2045'>2045</option>
                                <option value='2046'>2046</option>
                                <option value='2047'>2047</option>
                                <option value='2048'>2048</option>
                                <option value='2049'>2049</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4 fv-row fv-plugins-icon-container'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                            <span className=''>CVC</span>
                          </label>
                          <div className='position-relative'>
                            <input
                              defaultValue='000'
                              ref={inputCardCVVRef as any}
                              type='text'
                              className='form-control form-control-solid'
                              minLength={3}
                              maxLength={4}
                              placeholder='CVC'
                              name='card_cvv'
                            />
                            <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                              <span className='svg-icon svg-icon-2hx'>
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='M22 7H2V11H22V7Z' fill='currentColor' />
                                  <path
                                    opacity='0.3'
                                    d='M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="d-flex flex-stack">
                          <div className="me-5">
                            <label className="fs-6 fw-semibold form-label">Save Card for further billing?</label>
                            <div className="fs-7 fw-semibold text-muted">If you need more info, please check budget planning</div>
                          </div>
                          <label className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" defaultValue={1} defaultChecked={true} />
                            <span className="form-check-label fw-semibold text-muted">Save Card</span>
                          </label>
                        </div> */}
                    </div>
                  </div>
                </div>
              )}
              {odeme !== 'kendim' ? null : null}
              <div className='m-0'>
                <h1 className='fw-bold text-gray-800 mb-8'>Teslimat Yöntemi</h1>
                <div
                  className='ka-teslimatyontemi-container d-flex flex-equal gap-5 gap-xxl-9 px-0 mb-12'
                  data-kt-buttons='true'
                  data-kt-buttons-target='[data-kt-button]'
                  data-kt-initialized={1}
                >
                  <label
                    className={
                      'ka-teslimatyontemi btn bg-light btn-color-gray-600 border border-3 border-gray-100 w-100 px-4 border-active-primary btn-active-light-primary btn-active-text-gray-800' +
                      (teslimat === 'kendim' ? ' ka-sepet-active' : '')
                    }
                    data-kt-button='true'
                    onClick={() => {
                      setTeslimat('kendim')
                    }}
                  >
                    <input className='btn-check' type='radio' name='method' defaultValue={0} />
                    {/* <i className='fonticon-cash-payment fs-2hx mb-2 pe-0' /> */}
                    <KTSVG path='/media/icons/duotune/ecommerce/ecm005.svg' className='ka-sepet-icon' />
                    <span className='fs-4 fw-bold d-block mt-3'>Kendim Alacağım</span>
                    <p className='text-gray mt-2 fs-6'>
                      Eğer siparişi gelip kendiniz alacak iseniz lütfen bu seçeneği işaretleyiniz.
                    </p>
                  </label>
                  <label
                    className={
                      'ka-teslimatyontemi btn bg-light btn-color-gray-600 border border-3 border-gray-100 w-100 px-4 border-active-primary btn-active-light-primary btn-active-text-gray-800' +
                      (teslimat === 'kargo' ? ' ka-sepet-active' : '')
                    }
                    data-kt-button='true'
                    onClick={() => {
                      setTeslimat('kargo')
                    }}
                  >
                    <input className='btn-check' type='radio' name='method' defaultValue={0} />
                    {/* <i className='fonticon-cash-payment fs-2hx mb-2 pe-0' /> */}
                    <KTSVG path='/media/icons/duotune/ecommerce/ecm007.svg' className='ka-sepet-icon' />
                    <span className='fs-4 fw-bold d-block mt-3'>Kargo ile Teslimat</span>
                    <p className='text-gray mt-2 fs-6'>
                      Anlaşmalı kargomuz Aras kargodur ve aksi belirtilmedikçe aras kargoyla alıcı ödemeli gönderim
                      sağlanacaktır. Farklı kargo tercihiniz var ise müşteri notu bölümüne detayları belirtiniz.
                    </p>
                  </label>
                  <label
                    className={
                      'ka-teslimatyontemi btn bg-light btn-color-gray-600 border border-3 border-gray-100 w-100 px-4 border-active-primary btn-active-light-primary btn-active-text-gray-800' +
                      (teslimat === 'arac' ? ' ka-sepet-active' : '')
                    }
                    data-kt-button='true'
                    onClick={() => {
                      setTeslimat('arac')
                    }}
                  >
                    <input className='btn-check' type='radio' name='method' defaultValue={1} />
                    <KTSVG path='/media/icons/duotune/ecommerce/ecm006.svg' className='ka-sepet-icon' />
                    <span className='fs-4 fw-bold d-block mt-3'>Araç ile Teslimat</span>
                    <p className='text-gray mt-2 fs-6'>
                      İstanbul ve Ankara içi aracımızla teslim imkanı. Teslimat günü için sizinle iletişime
                      geçilecektir.
                    </p>
                  </label>
                </div>
              </div>
              <div className='m-0'>
                <h1 className='fw-bold text-gray-800 mb-8'>Müşteri Notu</h1>
                <div
                  className='d-flex flex-equal gap-5 gap-xxl-9 px-0 mb-12'
                  data-kt-buttons='true'
                  data-kt-buttons-target='[data-kt-button]'
                  data-kt-initialized={1}
                >
                  <textarea
                    ref={noteRef}
                    className='form-control'
                    rows={4}
                    placeholder='Sevk edilecek farklı adres bilgisini ve kargo tercihinizi bu alana yazabilirsiniz.'
                  ></textarea>
                </div>
              </div>
              <button
                disabled={the_count === 0 || total_price === 0}
                className='btn btn-primary fs-3 w-100 py-3'
                onClick={siparisVer}
              >
                Sipariş Ver
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export { CartPage }
