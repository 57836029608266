/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react'
import { useDidMount } from 'rooks'
import { Api } from '../../api'
import { LoadingService } from '../../services/loading.service'
import { CloudReturnedOrderType } from './recentOrdersGiven'
import moment from 'moment'
import 'moment/locale/tr'
import Select from 'react-select'
import { KTSVG } from '../../_metronic/helpers'
import { OrderStatus } from '../../types'

export const OrderStatusOptions = [
  { value: 'Onay Bekliyor', label: 'Onay Bekliyor' },
  { value: 'Onaylandı ve Hazırlanıyor', label: 'Onaylandı ve Hazırlanıyor' },
  { value: 'Kargoya Verildi', label: 'Kargoya Verildi' },
  { value: 'Sipariş Teslim Alınabilir', label: 'Sipariş Teslim Alınabilir', teslimat: 'kendim' },
  { value: 'Teslim Alındı', label: 'Teslim Alındı', teslimat: 'kendim' },
  { value: 'Dağıtım Aracımıza Yüklenecek', label: 'Dağıtım Aracımıza Yüklenecek', teslimat: 'arac' },
  { value: 'Adresinize Bırakıldı', label: 'Adresinize Bırakıldı', teslimat: 'arac' },
  { value: 'Parçalı Onay', label: 'Parçalı Onay' },
  { value: 'İptal Edildi', label: 'İptal Edildi' }
]

type RecentOrdersTakenProps = {
  className: string
  _orders?: CloudReturnedOrderType[]
}

type TakenOrderProps = {
  user: any
  code: string
  date: moment.Moment
  odeme: string
  teslimat: string
  total: number | string
  status: OrderStatus
}

export type OrderStatusSelectProps = {
  code: string
  status: OrderStatus
  teslimat: string
}

export const OrderStatusSelect: React.FC<OrderStatusSelectProps> = ({ code, status, teslimat }) => {
  const [selectedOption, setSelectedOption] = useState<{ value: string; label: string }>({
    value: status,
    label: status
  })

  const filteredOptions = [];

  // OrderStatusOptions dizisini döngüye alın
  for (const option of OrderStatusOptions) {
    // Eğer seçenek bir "teslimat" özelliği içeriyorsa ve bu özellik teslimat verisiyle eşleşiyorsa
    if (option.teslimat && option.teslimat === teslimat) {
      // Yalnızca "teslimat" verisi uyan kısmı ayırın ve yeni bir nesne olarak ekleyin
      const filteredOption = { ...option, teslimat: teslimat };
      filteredOptions.push(filteredOption);
    } else if (!option.teslimat) {
      // Eğer "teslimat" özelliği yoksa, direkt olarak ekleyin
      filteredOptions.push(option);
    }
  }

  async function onChange(val: { value: string; label: string }) {
    if (val.value === selectedOption.value) return

    if (val.value === 'İptal Edildi') {
      if (!window.confirm('Sipariş iptal etmek istediğinize emin misiniz?')) {
        return
      }
    }

    if (val.value === 'Teslim Alındı') {
      if (!window.confirm('Bu işlem geri alınamaz. Sipariş teslim alındı olarak işaretlenecek. Onaylıyor musunuz?')) {
        return
      }
    }

    if (val.value === 'Adresinize Bırakıldı') {
      if (!window.confirm('Bu işlem geri alınamaz. Sipariş adresinize bırakıldı olarak işaretlenecek. Onaylıyor musunuz?')) {
        return
      }
    }

    if (val.value === 'Kargoya Verildi') {
      if (!window.confirm('Bu işlem geri alınamaz. Sipariş kargoya verildi olarak işaretlenecek. Onaylıyor musunuz?')) {
        return
      }
    }

    LoadingService.show()

    const res = await Api.updateOrder(code, val.value)

    if (!res.success) {
      alert('Hata: ' + res.error.message)
      LoadingService.hide()
      return
    } else {
      window.dispatchEvent(new CustomEvent('ordersUpdated'))
      setSelectedOption(val)
    }

    LoadingService.hide()
  }

  if (selectedOption.value === 'İptal Edildi') {
    return (
      <div className='fw-semibold'>
        <span className='badge badge-light-danger'>İptal Edildi</span>
      </div>
    )
  }

  if (selectedOption.value === 'Teslim Alındı') {
    return (
      <div className='fw-semibold'>
        <span className='badge badge-light-success'>Teslim Alındı</span>
      </div>
    )
  }

  if (selectedOption.value === 'Adresinize Bırakıldı') {
    return (
      <div className='fw-semibold'>
        <span className='badge badge-light-success'>Adresinize Bırakıldı</span>
      </div>
    )
  }

  if (selectedOption.value === 'Kargoya Verildi') {
    return (
      <div className='fw-semibold'>
        <span className='badge badge-light-success'>Kargoya Verildi</span>
      </div>
    )
  }

  return (
    <div className='fw-semibold'>
      {selectedOption.value === 'İptal Edildi' ? (
        <span className='badge badge-light-danger'>İptal Edildi</span>
      ) : (
        <Select
          value={selectedOption}
          onChange={onChange as any}
          options={filteredOptions}
          isClearable={false}
          isSearchable={false}
        />
      )}
    </div>
  )
}

const GivenOrder: React.FC<TakenOrderProps> = ({ user, code, date, odeme, teslimat, total, status }) => {
  return (
    <tr>
      <td>
        <a href={`/siparis/${code}`} className='text-dark fw-bold text-hover-primary fs-6'>
          {code}
        </a>
      </td>
      <td>
        <a href={`/yonet/cari/${user.code}`} className='text-dark fw-bold text-hover-primary fs-6'>
          {user.name}
        </a>
      </td>
      <td>
        <span className='text-dark fw-bold fs-6'>{date.format('DD MMMM YYYY')}</span>
      </td>
      <td>
        <span className='text-dark fw-bold fs-6'>{date.format('HH:mm')}</span>
      </td>
      <td>
        <span className='text-dark fw-bold fs-6'>
          {odeme === 'cari' ? 'Cari Bakiye' : null}
          {odeme === 'online' ? 'Online Ödeme' : null}
        </span>
      </td>
      <td>
        <span className='text-dark fw-bold fs-6'>
          {teslimat === 'kendim' ? 'Kendim Alacağım' : null}
          {teslimat === 'arac' ? 'Araç ile Teslimat' : null}
          {teslimat === 'kargo' ? 'Kargo ile Teslimat' : null}
        </span>
      </td>
      <td className='text-dark fw-bold fs-6 text-end' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 6 }}>
        ${(+(parseFloat(String(total)) * 1.20)).toFixed(3).split('.').join(',')}
      </td>
      <td className='text-end pl-8'>
        <OrderStatusSelect code={code} status={status} teslimat={teslimat} />
      </td>
    </tr>
  )
}

const RecentOrdersTaken: React.FC<RecentOrdersTakenProps> = ({ className }) => {
  const [orders, setOrders] = useState<CloudReturnedOrderType[]>([])
  const [filteredOrders, setFilteredOrders] = useState<CloudReturnedOrderType[] | null>(null)
  const [selectedStatus, setSelectedStatus] = useState<{ value: string, label: string } | null>(null)
  const tableRef = useRef<HTMLTableElement | null>()

  const onFilter = (newValue: { value: string, label: string }) => {
    setSelectedStatus(newValue)

    if (newValue === null) {
      setFilteredOrders(null)
    } else {
      setFilteredOrders(orders.filter(v => v.status === newValue.value))
    }
  }

  const onExportOrders = () => {
    const list = orders.map((v: CloudReturnedOrderType) => {
      const date = moment(v.createdAt)
      let odemeYontemi = ''
      let teslimat = ''

      if (v.payment === 'cari') {
        odemeYontemi = 'Cari Bakiye'
      } else if (v.payment === 'online') {
        odemeYontemi = 'Online Ödeme'
      }

      if (v.delivery === 'kendim') {
        teslimat = 'Kendim Alacağım'
      } else if (v.delivery === 'arac') {
        teslimat = 'Araç ile Teslimat'
      } else if (v.delivery === 'kargo') {
        teslimat = 'Kargo ile Teslimat'
      }

      return [
        v.code.toUpperCase(),
        v.user.name,
        date.format('DD MMMM YYYY'),
        date.format('HH:mm'),
        odemeYontemi,
        teslimat,
        '$' + (+(parseFloat(String(v.total)) * 1.20)).toFixed(3),
        v.status,
      ]
    })

    const results: string[][] = [
      ['Sipariş Kodu', 'Alıcı', 'Tarih', 'Saat', 'Ödeme Yöntemi', 'Teslimat Yöntemi', 'Tutar', 'Durum'],
      ...list,
    ]

    let csvString = '';

    results.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        csvString += cell + (cellIndex < row.length - 1 ? ',' : '')
      });

      csvString += rowIndex < results.length - 1 ? '\n' : ''
    })

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'siparisler.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  useDidMount(async () => {
    LoadingService.show()
    moment.locale('tr')

    const res = await Api.listOrdersTaken()

    if (!res.success) {
      LoadingService.hide()
      return alert('Bir hata oluştu: ' + res.error?.message ?? '')
    } else {
      setOrders(res.data.orders)

      LoadingService.hide()
    }
  })

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Sipariş Yönetimi</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Sipariş detayına gitmek için lütfen sipariş koduna tıklayınız*
          </span>
          <span className='text-muted mt-1 fw-semibold fs-7'>100 siparişe kadar*</span>
        </h3>

        <div className='min-w-100 mb-5 mt-7 fv-plugins-icon-container'>
          <button className="btn btn-primary fs-5" onClick={onExportOrders}>Bütün siparişleri dışarı aktar</button>
        </div>
      </div>
      <div className='card-body py-3 mt-5'>
        <div className="row justify-content-end align-items-center mb-5">
          <div className='fw-semibold' style={{ width: 250 }}>
            <Select
              value={selectedStatus}
              onChange={onFilter as any}
              options={OrderStatusOptions}
              isClearable={true}
              isSearchable={false}
              placeholder="Sipariş Durumu Filtrele"
            />
          </div>

          <div className='row justify-content-end pe-4 w-100px'>
            <div
              className='ka-tablo-sol btn-primary btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => {
                if (!tableRef.current) return
                tableRef.current.scrollLeft -= 60
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2x' />
            </div>
            <div
              className='ka-tablo-sag btn btn-sm btn-primary btn-icon btn-active-color-primary ms-2'
              onClick={() => {
                if (!tableRef.current) return
                tableRef.current.scrollLeft += 60
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-2x' />
            </div>
          </div>
        </div>
        <div ref={tableRef as any} className='table-responsive' /* style={{ overflow: 'inherit' }} */>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px text-nowrap'>Sipariş Kodu</th>
                <th className='min-w-150px text-nowrap'>Alıcı</th>
                <th className='min-w-150px text-nowrap'>Tarih</th>
                <th className='min-w-120px text-nowrap'>Saat</th>
                <th className='min-w-120px text-nowrap'>Ödeme Yöntemi</th>
                <th className='min-w-150px text-nowrap'>Teslimat Yöntemi</th>
                <th className='min-w-100px text-nowrap text-end'>Tutar</th>
                <th className='min-w-200px text-end text-nowrap'>Durum</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders && (
                <>
                  {filteredOrders && filteredOrders.length === 0 ? (
                    <tr>
                      <td className='pt-6'>Herhangi bir sipariş kaydı bulunamadı...</td>
                    </tr>
                  ) : null}

                  {filteredOrders && filteredOrders.length > 0 && filteredOrders.map((v, i) => (
                    <GivenOrder
                      key={i}
                      user={v.user}
                      code={v.code.toUpperCase()}
                      date={moment(v.createdAt)}
                      odeme={v.payment}
                      teslimat={v.delivery}
                      total={v.total}
                      status={v.status}
                    />
                  ))}
                </>
              )}

              {!filteredOrders && (
                <>
                  {orders.length === 0 ? (
                    <tr>
                      <td className='pt-6'>Herhangi bir sipariş kaydı bulunamadı...</td>
                    </tr>
                  ) : (
                    orders.map((v, i) => (
                      <GivenOrder
                        key={i}
                        user={v.user}
                        code={v.code.toUpperCase()}
                        date={moment(v.createdAt)}
                        odeme={v.payment}
                        teslimat={v.delivery}
                        total={v.total}
                        status={v.status}
                      />
                    ))
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export { RecentOrdersTaken }
