/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Api } from '../../../../../api'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  let udata: any = {}

  try {
    udata = Api.getLocalUserData()
  } catch {
    return null
  }

  return (
    <>
      <SidebarMenuItem
        to='/anasayfa'
        icon='/media/icons/duotune/art/art002.svg'
        title='Anasayfa'
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem
        to='/urunler'
        icon='/media/icons/duotune/abstract/abs015.svg'
        title='Tum Urunler'
        fontIcon='bi-list'
      /> */}
      {/* <SidebarMenuItem
        to='/sikca-sorulan-sorular'
        icon='/media/icons/duotune/general/gen051.svg'
        title='S.S.S'
        fontIcon='bi-layers'
      /> */}
      {/* <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Arayüz Ayarları'
        fontIcon='bi-layers'
      /> */}
      {udata.type === 'user' ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>SAYFALARIM</span>
            </div>
          </div>
          {/* <SidebarMenuItem to='/hesabim' icon='/media/icons/duotune/communication/com006.svg' title='Hesabım' /> */}
          <SidebarMenuItem to='/hareketler' icon='/media/icons/duotune/finance/fin007.svg' title='Hareketlerim' />
          <SidebarMenuItem to='/onlineodeme' icon='/media/icons/duotune/finance/fin002.svg' title='Online Ödeme' />
          {/* <SidebarMenuItem to='/hesabim/duzenle' title='Ayarlar' hasBullet={true} /> */}
          {/* </SidebarMenuItemWithSub> */}
          <SidebarMenuItem
            to='/siparisler'
            icon='/media/icons/duotune/ecommerce/ecm006.svg'
            title='Siparişlerim'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/sepet'
            icon='/media/icons/duotune/ecommerce/ecm002.svg'
            title='Sepetim'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/iletisim'
            icon='/media/icons/duotune/communication/com002.svg'
            title='İletişim'
            fontIcon='bi-layers'
          />
        </>
      ) : null}

      {/* <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {udata.type === 'admin' ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Yönet</span>
            </div>
          </div>
          <SidebarMenuItem
            to='/yonet/anasayfa'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Anasayfa Yönetim'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/yonet/excel'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Excel Yönetim'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/yonet/siparisler'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Siparişler'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/yonet/cariler'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Cariler'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/yonet/odemeler'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Online Ödemeler'
            fontIcon='bi-layers'
          />
        </>
      ) : null}

      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
    </>
  )
}

export { SidebarMenuMain }
