import { FC, useState, useEffect, createContext, useContext, useRef, Dispatch, SetStateAction } from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { WithChildren } from '../../../../_metronic/helpers'
import { Api } from '../../../../api'

// @ts-ignore
import Loading from 'react-fullscreen-loading'
import { LoadingService } from '../../../../services/loading.service'
import { useService } from '../../../../hooks/use-service'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {}
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const isLoading = useService<boolean>(LoadingService, false)

  // if (Api.isAdmin()) window.document.getElementById('root')?.classList.add('isadmin')

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    localStorage.removeItem('__userdata')
  }

  return (
    <>
      <Loading loading={isLoading} background='rgba(255,255,255,0.7)' loaderColor='#3498db' />
      <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
        {children}
      </AuthContext.Provider>
    </>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async (/* apiToken: string */) => {
      try {
        if (!didRequest.current) {
          //   const { data } = await getUserByToken(apiToken)
          try {
            const loc = localStorage.getItem('__userdata')
            if (!loc) {
              window.location.reload()
            } else {
              const localData = JSON.parse(loc)
              const refreshData = await Api.refresh(localData.data!.code, localData.data!.token)
              if (!refreshData || !refreshData.success || localData.isNew === false) {
                if (!refreshData) {
                  alert('Kullanıcı bilgileri çekilemedi, lütfen daha sonra tekrar deneyiniz.')
                  window.location.reload()
                } else {
                  localStorage.removeItem('__userdata')
                }
              } else {
                const themedata = Api.data2themedata(refreshData)
                localStorage.setItem('__userdata', JSON.stringify(themedata))
                setCurrentUser(themedata)
              }
            }
          } catch {
            // localStorage.removeItem('__userdata')
            alert('Kullanıcı bilgileri çekilemedi, lütfen daha sonra tekrar deneyiniz.')
            window.location.reload()
            return
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (/* auth && auth.api_token */ localStorage.getItem('__userdata')) {
      requestUser()
      if (Api.isAdmin()) {
        window.document.getElementById('root')?.classList.add('isadmin')
      } else {
        window.document.getElementById('root')?.classList.remove('isadmin')
      }
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
