/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useRef } from 'react'
import { Api } from '../../api'
import { LoadingService } from '../../services/loading.service'

const ContactPage: FC = () => {
  const subjectRef = useRef<HTMLInputElement>(null)
  const messageRef = useRef<HTMLTextAreaElement>(null)

  if (Api.isAdmin()) {
    window.location.replace('/hata/404')
    return null
  }

  async function send() {
    LoadingService.show()

    const subject = subjectRef.current?.value ?? ''
    const message = messageRef.current?.value ?? ''

    if (!subject || !message) {
      LoadingService.hide()
      alert('Mesaj konusu ve mesaj alanı boş bırakılamaz.')
      return
    }

    const res = await Api.sendMail(subject, message)

    LoadingService.hide()
    if (res.success) {
      alert('Mesajınız başarıyla iletilmiştir.')
      subjectRef.current!.value = ''
      messageRef.current!.value = ''
    } else {
      alert('Mesaj gönderilirken sistemde bilinmeyen bir hata oluştu')
      console.log(res)
    }
  }

  return (
    <div className='card'>
      <div className='card-body p-lg-17'>
        <div className='row mb-3'>
          <div className='col-md-6 pe-lg-10'>
            <div className='form mb-15 fv-plugins-bootstrap5 fv-plugins-framework' id='kt_contact_form'>
              <h1 className='fw-bold text-dark mb-15'>Bizimle İletişime Geçin</h1>
              <div className='d-flex flex-column mb-5 fv-row'>
                <label className='fs-5 fw-semibold mb-2'>Mesaj Konusu</label>
                <input
                  ref={subjectRef as any}
                  className='form-control form-control-solid'
                  placeholder='Konu'
                  name='subject'
                />
              </div>
              <div className='d-flex flex-column mb-10 fv-row fv-plugins-icon-container'>
                <label className='fs-6 fw-semibold mb-2'>Mesajınız</label>
                <textarea
                  ref={messageRef as any}
                  className='form-control form-control-solid'
                  rows={6}
                  name='message'
                  placeholder='Mesajınız'
                  defaultValue=''
                  style={{ resize: 'none' }}
                />
                <div className='fv-plugins-message-container invalid-feedback' />
              </div>
              <button className='btn btn-primary' id='kt_contact_submit_button'>
                <span className='indicator-label' onClick={send}>
                  Gönder
                </span>
                {/* <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span> */}
              </button>
            </div>
          </div>
          <div className='col-md-6 ps-lg-10'>
            <div
              id='kt_contact_map'
              className='w-100 rounded mb-2 mb-lg-0 mt-2 leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom'
              style={{ height: '486px', position: 'relative' }}
              tabIndex={0}
            >
              <iframe
                title='3adf-saej'
                width='100%'
                height='450'
                style={{
                  border: 0,
                  borderRadius: 8.125,
                  outline: '1px solid rgba(0,0,0,.2)'
                }}
                loading='lazy'
                allowFullScreen
                referrerPolicy='no-referrer-when-downgrade'
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.2443388290935!2d28.901215315432076!3d41.019910026657826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cabaf92da9c89d%3A0xc732600719ea34ab!2sBarem%20Makina%20%26%20Filtre!5e0!3m2!1str!2str!4v1625821282733!5m2!1str!2str'
              ></iframe>
            </div>
          </div>
        </div>
        <div className='row g-5 mb-5 mb-lg-15'>
          <div className='col-sm-6 pe-lg-10'>
            <div className='text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-100'>
              <span className='svg-icon svg-icon-3tx svg-icon-primary'>
                <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    opacity='0.3'
                    d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                    fill='currentColor'
                  />
                  <path
                    d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <h1 className='text-dark fw-bold my-5'>Merkez Şube</h1>
              <div className='text-gray-700 fw-semibold fs-2'>
                0212 482 6155
                <br />
                <a href='mailto:info@baremmakina.com.tr'>info@baremmakina.com.tr</a>
                <br />
                Davutpaşa Cd. Kale İş Merk No:151 Topkapı/İSTANBUL
              </div>
            </div>
          </div>
          <div className='col-sm-6 ps-lg-10'>
            <div className='text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-100'>
              <span className='svg-icon svg-icon-3tx svg-icon-primary'>
                <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    opacity='0.3'
                    d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                    fill='currentColor'
                  />
                  <path
                    d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <h1 className='text-dark fw-bold my-5'>Şube</h1>
              <div className='text-gray-700 fw-semibold fs-2'>
                0312 385 6155
                <br />
                <a href='mailto:sube@baremmakina.com.tr'>sube@baremmakina.com.tr</a>
                <br />
                Ostim 1271. Cad No:15 Yenimahalle/ANKARA
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ContactPage }
