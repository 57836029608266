/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Api } from '../../../../api'
import { useAuth } from '../../../../app/modules/auth'

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth()

  let udata: any = {}

  try {
    udata = Api.getLocalUserData()
  } catch {
    return null
  }

  const isAdmin = udata.type === 'admin'

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {/* <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} /> */}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.fullname}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <span className='fw-bold text-muted fs-7'>{currentUser?.email}</span>
          </div>
        </div>
      </div>

      {isAdmin ? <div className='separator my-2'></div> : null}

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div> */}

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div> */}

      {/* <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}

      {/* {isAdmin ? null : (
        <>
          <div className='menu-item px-5'>
            <a href='/hesabim' className='menu-link px-5'>
              Hesabım
            </a>
          </div>
        </>
      )} */}

      {/* <Languages /> */}

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/hesabim/duzenle' className='menu-link px-5'>
          Hesap Ayarları
        </Link>
      </div> */}

      {!isAdmin ? null : (
        <div className='menu-item px-5'>
          <a onClick={logout} className='menu-link px-5'>
            Çıkış Yap
          </a>
        </div>
      )}
    </div>
  )
}

export { HeaderUserMenu }
