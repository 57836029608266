import React, { useRef, useState } from 'react'
import { KTSVG } from '../../../helpers'

const Search: React.FC = () => {
  const element = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement>()
  const [focuserEnabled, setFocuserEnabled] = useState(false)
  const [mobileFocuserEnabled, setMobileFocuserEnabled] = useState(false)

  function handleKeydown(e: any) {
    if (e === true || e.key === 'Enter') {
      if (e.key === 'Enter') e.preventDefault()
      const val = inputRef.current!.value
      if (!val) return
      const url = `/urunler?search=${encodeURIComponent(val)}`
      window.location.href = url
    }
  }

  return (
    <>
      <div
        className='d-flex align-items-stretch'
        id='ka-id-searchproduct'
        ref={element}
      >
        <div className='d-flex align-items-center'>
          <div
            id='ka-search-div'
            className={
              'w-auto btn btn-icon btn-active-light-primary active' + (mobileFocuserEnabled ? ' mobile-active' : '')
            }
          >
            <input
              ref={inputRef as any}
              type='text'
              className='form-control form-control-flush'
              name='search'
              placeholder='Filtre numarasını giriniz...'
              data-kt-search-element='input'
              onKeyDown={(e) => handleKeydown(e)}
              onFocus={() => setFocuserEnabled(true)}
              onBlur={() => setFocuserEnabled(false)}
            />
            <div onClick={() => handleKeydown(true)}>
              <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 px-5' />
            </div>
          </div>
          <div
            id='ka-search-back'
            className={focuserEnabled || mobileFocuserEnabled ? ' active' : ''}
            onClick={() => {
              setFocuserEnabled(false)
              setMobileFocuserEnabled(false)
            }}
          />
        </div>
      </div>
      <div
        id='ka-searchproduct-mobile'
        className='d-flex align-items-center'
        onClick={() => setMobileFocuserEnabled(!mobileFocuserEnabled)}
      >
        <div className='w-auto btn btn-icon btn-active-light-primary active'>
          <div onClick={() => handleKeydown(true)}>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 px-5' />
          </div>
        </div>
      </div>
    </>
  )
}

export { Search }
