import { Api } from '../api'
import { BasketService } from '../services/basket.service'
import { LoadingService } from '../services/loading.service'

export interface ProductProps {
  name: string
  code: string
  category: string
  src?: string
  price: string | number
  comment?: string
  availability: number
}

export const Product = (props: ProductProps) => {
  let udata = Api.getLocalUserData()

  if (!udata) return null

  const picUrl = Api.createProductUrl(props.code)

  async function ekle() {
    LoadingService.show()
    let prevp = BasketService.getLocalBasketDataList()
    if (prevp) prevp += ','
    let prevc = BasketService.getLocalBasketDataCount()
    if (prevc) prevc += ','

    await Api.updateBasket(prevp + props.code, prevc + '1')

    await BasketService.downloadAndUpdateBasketData()

    LoadingService.hide()
  }

  const brandDiscount = Api.getDiscount(props.category)
  const brandDiscountPrice = +(parseFloat(String(props.price)) * (1 - brandDiscount)).toFixed(3)
  const specificPrice = Api.getDiscountedForCode(props.code)

  return (
    <div
      className={
        'card card-flush flex-row-fluid p-3 pb-5 mw-100 ka-product2' +
        (props.availability <= 0 ? ' ka-product2-disabled' : '')
      }
    >
      <div className='card-body text-center'>
        <div className='ka-product2-imgcontainer rounded-3 mb-4 w-150px h-150px w-xxl-200px h-xxl-200px'>
          <img
            src={picUrl}
            alt=''
            onError={({ currentTarget }) => {
            //   currentTarget.onerror = null
            //   currentTarget.src = toAbsoluteUrl('/fallback.png')
            }}
          />
        </div>
        <div className='mb-2'>
          <div className='text-center'>
            <span className='fw-bold text-gray-800 fs-3 fs-xl-1'>{props.code}</span>
            <span className='text-gray-400 fw-semibold d-block fs-6 mt-n1'>{props.name}</span>
            <span className='text-gray-500 fw-semibold d-block fs-6 mt-1'>
              {props.comment ? props.comment : <>&ensp;</>}
            </span>
          </div>
        </div>
        <span className='text-success text-end fw-bold fs-1'>
          {specificPrice ? (
            <>
              <del className='text-danger opacity-75'>${brandDiscountPrice.toString().split('.').join(',')}</del> $
              {specificPrice.toString().split('.').join(',')}
            </>
          ) : (
            <>${brandDiscountPrice.toString().split('.').join(',')}</>
          )}
        </span>
        <button className='ka-product2-sendcart' onClick={ekle}>
          Sepete Ekle
        </button>
      </div>
    </div>
  )
}
