/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { toAbsoluteUrl } from '../_metronic/helpers'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import { Pagination, Autoplay } from 'swiper'

type Props = {
  className: string
}

const HomeSlider: React.FC<Props> = ({ className }) => {
  return (
    <div className={`ka-homeslider ${className}`}>
      <Swiper
        pagination={true}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }}
        modules={[Pagination, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
      >
        <SwiperSlide>
          <img src={toAbsoluteUrl('/slides/slider1.jpg')} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={toAbsoluteUrl('/slides/slider2.jpg')} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={toAbsoluteUrl('/slides/slider3.jpg')} alt='' />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export { HomeSlider }
