import { Subject } from 'rxjs'
import { Service } from './service'

export class LoadingService implements Service<boolean> {
  protected static isLoadingSubject = new Subject<boolean>()

  public static show(): void {
    LoadingService.update(true)
  }

  public static hide(afterms: number = 0): void {
    setTimeout(() => {
      LoadingService.update(false)
    }, afterms)
  }

  public static update(options: boolean): void {
    LoadingService.isLoadingSubject.next(options)
  }

  public static subscribe(event: 'update', callback: (obj: boolean) => void): void {
    if (event === 'update') {
      LoadingService.isLoadingSubject.asObservable().subscribe(callback)
      return
    }
    throw new Error(`Unknown event: ${event}`)
  }
}
