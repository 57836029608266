import { Subject } from 'rxjs'
import { Api } from '../api'
import { LoadingService } from './loading.service'
import { Service } from './service'

export interface BasketServiceItemObject {
  name: string
  code: string
  category: string
  itemPrice: number
  count: string // int
}

export interface BasketServiceObject {
  items: BasketServiceItemObject[]
}

export class BasketService implements Service<BasketServiceObject> {
  protected static navbarSubject = new Subject<BasketServiceObject>()

  public static async downloadAndUpdateBasketData() {
    LoadingService.show()
    const basket = await Api.listBasket()

    if (basket.success) {
      const { list, listStr, countStr } = basket.data
      const count = countStr.split(',')

      BasketService.setLocalBasketDataList(listStr)
      BasketService.setLocalBasketDataCount(countStr)
      BasketService.update({
        items: list.map((v: any, i: number) => ({
          name: v.name,
          code: v.code,
          category: v.category,
          itemPrice: v.price,
          count: count[i] // int
        }))
      })
    } else {
      alert('Sepet yüklenirken bir hata oluştu: ' + basket.error.message)
    }

    LoadingService.hide()
  }

  public static async uploadBasketData(items: BasketServiceItemObject[]) {
    BasketService.update({ items: items })

    LoadingService.show()
    let list = []
    let count = []
    for (const item of items) {
      list.push(item.code)
      count.push(item.count)
    }
    // const list = BasketService.getLocalBasketDataList()
    // const count = BasketService.getLocalBasketDataCount()
    await Api.updateBasket(list.join(','), count.join(','))
    // await BasketService.downloadAndUpdateBasketData()
    LoadingService.hide()
  }

  public static getLocalBasketDataList(): string {
    return localStorage.getItem('__basketdata') ?? ''
  }

  public static getLocalBasketDataCount(): string {
    return localStorage.getItem('__basketcount') ?? ''
  }

  public static setLocalBasketDataList(data: string): void {
    localStorage.setItem('__basketdata', data)
  }

  public static setLocalBasketDataCount(data: string): void {
    localStorage.setItem('__basketcount', data)
  }

  // public static getOnlineBasketData(): string {

  // }

  public static async clearBasket() {
    await BasketService.uploadBasketData([])
    window.location.reload()
  }

  public static update(options: BasketServiceObject): void {
    BasketService.navbarSubject.next(options)
  }

  public static subscribe(event: 'update', callback: (obj: BasketServiceObject) => void): void {
    if (event === 'update') {
      BasketService.navbarSubject.asObservable().subscribe(callback)
      return
    }
    throw new Error(`Unknown event: ${event}`)
  }
}
